import React, { useContext, useEffect, useCallback, useMemo, useRef } from 'react'
import Auth from '../../context/Auth'
import { ReactSVG } from 'react-svg'
import Cta from '../_control/Cta'
import { css } from '@emotion/react'
import SwitcherSimple from '../_control/SwitcherSimple'
import { fonts, colors } from '../../style/vars'
import PitchListContext from '../../context/PitchList'
import ChangePasswordCard from '../settings/ChangePasswordCard'
import ProfileContext from '../../context/Profile'
import userIcon from '../../assets/icons/account_circle.svg'
import ContactForm from '../settings/ContactForm'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { useSearchParams } from 'react-router-dom'
import LanguageSelector from '../_control/LanguageSelector'
import { FormattedMessage, useIntl } from 'react-intl'
import WeekStartSelector from '../settings/WeekStartSelector'
import ReporterTimezoneSelector from '../settings/ReporterTimezoneSelector'
import Loader from '../_general/Loader'
import AdminAnalyticsClubSelector from '../settings/AdminAnalyticsClubSelector'

function AccountSettings({ ...props }) {
    const { logOut } = useContext(Auth)
    const [profile] = useContext(ProfileContext)
    const { sortAlphabetical, setSortAlphabetical } = useContext(PitchListContext)
    const { post } = useLdjsonApi()
    const contactRef = useRef()

    const [params] = useSearchParams()

    const contactPrefill = useMemo(() => {
        return params?.get('contactPrefill')
    }, [params])

    useEffect(() => {
        if (contactRef.current && contactPrefill) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])

    const submitNewPassword = useCallback(async (password, onSuccessCallback) => {
        const res = await post(`/api/current/password`, {
            body: {
                password,
            }
        })
        if (res?.data) {
            onSuccessCallback?.()
        } else {
            throw new Error('response did not contain data')
        }
    }, [])

    const { formatMessage } = useIntl()

    return (!profile ? <Loader /> :
        <div css={style.pageWrapper}>
            <div css={css`
                display: grid;
                place-items: center;
            `}>
                <h1 css={[style.title, css`display: flex; align-items:center; color: ${colors.soft};`]}>
                    <ReactSVG
                        css={css`fill: ${colors.soft}; width: 1.5em;`}
                        src={userIcon}
                    />
                    {profile?.firstName} {profile?.lastName}
                </h1>
                <Cta
                    onClick={() => { logOut() }}
                    signal='subtle'
                    style={style.button}
                    css={css`width: 100%; margin-top: 1em;`}
                >
                    <FormattedMessage id='logOut' />
                </Cta>
            </div>
            <h1 css={style.title}>
                <FormattedMessage id='settings' />
            </h1>
            <div css={style.settingsGrid}>

                <h2>
                    <FormattedMessage id='language' />
                </h2>
                <LanguageSelector />

                <h2>
                    <FormattedMessage id='startOfWeek' />
                </h2>
                <WeekStartSelector />

                <h2>
                    <FormattedMessage id='timezoneForReporterGraphs' />
                </h2>
                <ReporterTimezoneSelector />

                <h2>
                    <FormattedMessage id='pitchOrderOption' />
                </h2>
                <SwitcherSimple
                    checked={sortAlphabetical}
                    onClick={() => setSortAlphabetical(current => !current)}
                    checkedLabel={formatMessage({ id: 'alphabetical' })}
                    unCheckedLabel={formatMessage({ id: 'venueImportance' })}
                    buttonCenter={true}
                    css={css`width: max-content;`}
                />

                <h2>
                    <FormattedMessage id='allowTelemetryOption' />
                </h2>
                <TrackingOptOut /> {/* TO DO: privacy policy */}

                {profile?.admin &&
                    <>
                        <div css={css`height: 2em;`} /><div />
                        
                        <h2 title='All this does is filter the pitch selector list and dashboard picker sport.  Data is still dependent on whatever tableau license is configured for your user'>
                            *Admin* Advanced Analytics context club
                        </h2>
                        <AdminAnalyticsClubSelector />
                    </>}
            </div>

            <h1 css={style.title}>
                <FormattedMessage id='changePass' />
            </h1>
            <ChangePasswordCard submitPassword={submitNewPassword} css={css`        
                border-radius: 5px;
                border: 2px solid ${colors.eventDark};
            `} />

            <h1 css={style.title} ref={contactRef}>
                <FormattedMessage id='contactFormTitle' />
            </h1>
            <ContactForm prefill={contactPrefill} />
        </div>
    )
}

const TrackingOptOut = () => {

    const [profile] = useContext(ProfileContext)

    useEffect(() => {
        if (!!profile?.admin) return
        const script = document.createElement('script')
        script.src = 'https://rawstadia.matomo.cloud/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=0'
        script.async = true
        document.body.appendChild(script)
        return () => document.body.removeChild(script)
    }, [])

    return profile?.admin ?
        <div>
            <FormattedMessage id='adminsExcluded' />
        </div>
        :
        <div id="matomo-opt-out" css={css`
        display: flex;
        align-items: center;
        filter: grayscale(0.8);

        p { 
            color: ${colors.white} !important; 
            font-weight: normal !important;
            margin-left: 0.5em;
        }

        > input {
            appearance: auto;
            width: 20px;
            height: 20px;
        }

        strong {
            font-weight: normal;
        }

        label {
            display: none;
        }
    `} />
}

const style = {
    pageWrapper: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2em;
        width: min(60em, 100%);
        place-self: center;
    `,
    button: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0.5em',
    },
    title: css`
        font-family: ${fonts.special};
        font-size: 1.5em;
        align-self: center;
        margin-top: 3em;
        color: ${colors.soft};
    `,
    settingsGrid: css`
        margin-top: 1em;
        display: grid;
        grid-template-columns: 6fr 4fr;
        background: ${colors.substance};
        gap: 1em;
        padding: 1.5em 1.5em 2em 1.5em;
        width: 100%;
        border-radius: 5px;
        border: 2px solid ${colors.eventDark};

        h2 {
            justify-self: start;
            display: grid; 
            place-items: center; 
            color: ${colors.soft};
        }

        @media screen and (max-width: 600px) {
            grid-template: 2em / 1fr;

            h2 {
                margin-top: 1em;
            }
        }
    `,
}

export default AccountSettings
