import React, { useLayoutEffect, useMemo, useRef, useState, useContext, useCallback } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { scaleLinear, scaleBand, timeFormat } from 'd3'
import { format } from 'date-fns'
import { colors } from '../../style/vars'
import ReporterContext from '../../context/Reporter'
import { roundToOne, toAveragedPerDay } from './util'
import LocaleContext from '../../context/Locale'

export default function GenericDiscreteBarTimeseries({
    overlay,
    data,
    compareData,
    yAxisOneMin,
    yAxisOneMax,
    yAxisTwoMin,
    yAxisTwoMax,
    ...props
}) {
    const wrap = useRef()
    const { lineColor1, lineColor2, useResultsAsAxis, highlightDate, setHighlightDate, comparing, reporterBusy } = useContext(ReporterContext)
    const [dim, setDim] = useState({ w: 0, h: 0 })

    const { datefnsLocale } = useContext(LocaleContext)

    useLayoutEffect(() => {
        function recalcDims() {
            if (wrap.current) {
                const { width, height } = wrap.current.getBoundingClientRect()
                setDim({ w: width, h: height })
            }
        }
        recalcDims()
        window.addEventListener('resize', recalcDims)
        return () => window.removeEventListener('resize', recalcDims)
    }, [])

    const margin = useMemo(() => ({ t: 24, b: 24, l: 46, r: 46 }), [])
    const sizes = useMemo(() => ({
        contW: dim.w,
        contH: dim.h,
        graphW: dim.w - margin.l - margin.r,
        graphH: dim.h - margin.t - margin.b,
    }), [dim, margin])

    const [dayAggregateData, compareDayAggregateData] = useMemo(() => [
        toAveragedPerDay(data),
        toAveragedPerDay(compareData)
    ], [data, compareData])

    const combinedSortedData = useMemo(() => {
        const combinedData = [...dayAggregateData, ...compareDayAggregateData]
        combinedData.sort((a, b) => a.timestamp - b.timestamp)
        return combinedData
    }, [dayAggregateData, compareDayAggregateData])

    const xScale = useMemo(() => {
        return scaleBand()
            .domain(combinedSortedData.map(d => new Date(d.date).setHours(0, 0, 0, 0)))
            .range([0, sizes.graphW])
    }, [combinedSortedData, sizes])

    const yScale = useMemo(() => (
        scaleLinear()
            .domain([yAxisOneMin, yAxisOneMax])
            .range([0, sizes.graphH])
    ), [sizes, yAxisOneMax, yAxisOneMin])

    const yScale2 = useMemo(() => (
        scaleLinear()
            .domain([yAxisTwoMin, yAxisTwoMax])
            .range([0, sizes.graphH])
    ), [sizes, yAxisTwoMax, yAxisTwoMin])

    const generateYTicks = useCallback((scale, axisMax, useResultsAsAxis) => {
        if (useResultsAsAxis && axisMax) {
            const [min, max] = scale.domain()
            const step = (max - min) / 10
            let arr = []

            for (let x = min; x <= max; x = parseFloat((x + step).toPrecision(15))) {
                arr.push(x)
            }

            return arr.map(value => ({
                label: value,
                yOffset: scale(value),
            }))
        }

        return scale.ticks(10).map(value => ({
            label: value,
            yOffset: scale(value),
        }))
    }, [])

    const yTicks = useMemo(() => {
        return generateYTicks(yScale, yAxisOneMax, useResultsAsAxis)
    }, [yScale, yAxisOneMax, useResultsAsAxis, generateYTicks])

    const yTicks2 = useMemo(() => {
        return generateYTicks(yScale2, yAxisTwoMax, useResultsAsAxis)
    }, [yScale2, yAxisTwoMax, useResultsAsAxis])

    //X axis gridlines on each new month
    const gridlineDates = useMemo(() => {
        const resultIndices = []
        for (let i = 1; i < combinedSortedData.length; i++) {
            const previousDate = combinedSortedData[i - 1].date;
            const currentDate = combinedSortedData[i].date;
            const previousMonth = previousDate.getMonth()
            const currentMonth = currentDate.getMonth()
            const previousYear = previousDate.getFullYear()
            const currentYear = currentDate.getFullYear()
            if (currentYear > previousYear || (currentYear === previousYear && currentMonth > previousMonth)) {
                resultIndices.push(i)
            }
        }
        return combinedSortedData.filter((x, i) => resultIndices.includes(i))
    }, [combinedSortedData, xScale, yScale, sizes])

    const styles = {
        wrapper: css`
            width: 100%;
            height: 100%;
            position: relative;
            userSelect: none;
            opacity: ${reporterBusy ? 0.25 : 1};
        `,
        overlay: css`
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointerEvents: none;
        `
    }

    return (
        <div style={props.style}>
            <div ref={wrap} css={styles.wrapper} >
                {overlay &&
                    <div css={styles.overlay}>
                        {overlay}
                    </div>
                }
                <svg width={sizes.contW} height={sizes.contH}>
                    {/* graph */}
                    <g transform={`translate(${margin.l} ${margin.t})`}>
                        {/* X axis */}
                        <g transform={`translate(0 ${sizes.graphH})`}>
                            {xScale.domain().map((date, index) => {
                                const x = xScale(date)
                                const xDelta = xScale.bandwidth() / 2
                                return (
                                    <g key={index} transform={`translate(${x}, 0)`}>
                                        <line y2={6} stroke='currentColor' strokeWidth={2} />
                                        <text
                                            style={{
                                                fontSize: '10px',
                                                textAnchor: 'middle',
                                                transform: `translate(${xDelta}px, 20px)`,
                                                fill: 'currentColor'
                                            }}
                                        >
                                            {format(date, 'd MMM', { locale: datefnsLocale })}
                                        </text>
                                    </g>
                                )
                            })}

                            <path
                                d={`M 0 0 H ${sizes.graphW}`}
                                stroke='currentColor'
                                strokeWidth={2}
                            />
                        </g>

                        {/* X axis gridlines on each new month */}
                        <g>
                            {gridlineDates.map(({ date }, index) => (
                                <line
                                    key={`${date}-${index}`}
                                    x1={xScale(new Date(date).setHours(0, 0, 0, 0))}
                                    x2={xScale(new Date(date).setHours(0, 0, 0, 0))}
                                    y1={0}
                                    y2={sizes.graphH}
                                    stroke='currentColor'
                                    opacity={0.1}
                                    strokeWidth={1}
                                />
                            ))}
                        </g>

                        {/* Y axis */}
                        <g transform={`translate(0 ${sizes.graphH})`}>
                            <path
                                d={`M 0 0 V ${-sizes.graphH}`}
                                stroke={lineColor1}
                                strokeWidth={2}
                            />
                            {yTicks.map(({ label, yOffset }, index) => (
                                <g
                                    key={index}
                                    transform={`translate(0 ${-yOffset})`}
                                >
                                    <line
                                        x2={-6}
                                        stroke='currentColor'
                                        strokeWidth={2}
                                    />
                                    <text
                                        key={index}
                                        style={{
                                            fontSize: '10px',
                                            textAnchor: 'end',
                                            transform: 'translate(-16px, 4px)',
                                            fill: colors.white,
                                        }}
                                    >
                                        {roundToOne(label)}
                                    </text>

                                    <line
                                        x1={0}
                                        x2={sizes.graphW}
                                        stroke='currentColor'
                                        opacity={0.1}
                                        strokeWidth={1}
                                    />

                                </g>
                            ))}
                        </g>

                        {/* Y axis 2*/}
                        {comparing && <g transform={`translate(${sizes.graphW} ${sizes.graphH})`}>
                            <path
                                d={`M 0 0 V ${-sizes.graphH}`}
                                stroke={lineColor2}
                                strokeWidth={2}
                            />
                            {yTicks2.map(({ label, yOffset }, index) => (
                                <g key={index} transform={`translate(0 ${-yOffset})`} >
                                    <line
                                        x2={6}
                                        stroke='currentColor'
                                        strokeWidth={2}
                                    />
                                    <text
                                        key={index}
                                        style={{
                                            fontSize: '10px',
                                            textAnchor: 'start',
                                            transform: 'translate(16px, 4px)',
                                            fill: colors.white,
                                        }}
                                    >
                                        {roundToOne(label)}
                                    </text>
                                </g>
                            ))}
                        </g>}

                        {/* bars of data 1 */}
                        <g>
                            {dayAggregateData.map(({ date, value, isAggregate }, index) => {
                                const zeroHourDate = new Date(date.getTime())
                                zeroHourDate.setHours(0, 0, 0, 0)
                                const middleBandDate = new Date(date.getTime())
                                middleBandDate.setHours(12, 0, 0, 0)
                                return <rect
                                    css={css`
                                        cursor: pointer;
                                        opacity: ${highlightDate?.getTime() === middleBandDate.getTime() && 0.7}
                                    `}
                                    onClick={() => setHighlightDate(middleBandDate)}
                                    onMouseEnter={() => setHighlightDate(middleBandDate)}
                                    key={`${index}-${zeroHourDate}`}
                                    x={xScale(zeroHourDate) + xScale.bandwidth() * 0.05}
                                    y={sizes.graphH - yScale(value)}
                                    width={comparing ?
                                        (xScale.bandwidth() * 0.9) / 2
                                        : (xScale.bandwidth() * 0.9)}
                                    height={yScale(value)}
                                    fill={lineColor1}
                                    stroke={isAggregate ? colors.white : 'none'}
                                    strokeWidth={isAggregate ? '1px' : '0px'}
                                />
                            })}
                        </g>

                        {/* bars of data 2 */}
                        {comparing && <g>
                            {compareDayAggregateData.map(({ date, value, isAggregate }, index) => {
                                const zeroHourDate = new Date(date.getTime())
                                zeroHourDate.setHours(0, 0, 0, 0)
                                const middleBandDate = new Date(date.getTime())
                                middleBandDate.setHours(12, 0, 0, 0)
                                return <rect
                                    css={css`
                                        cursor: pointer; 
                                        opacity: ${highlightDate?.getTime() === middleBandDate.getTime() && 0.7}
                                    `}
                                    onClick={() => setHighlightDate(middleBandDate)}
                                    onMouseEnter={() => setHighlightDate(middleBandDate)}
                                    key={`${index}-${zeroHourDate}`}
                                    x={xScale(zeroHourDate) + xScale.bandwidth() / 2}
                                    y={sizes.graphH - yScale2(value)}
                                    width={(xScale.bandwidth() * 0.9) / 2}
                                    height={yScale2(value)}
                                    fill={lineColor2}
                                    stroke={isAggregate ? colors.white : 'none'}
                                    strokeWidth={isAggregate ? '1px' : '0px'}
                                />
                            })}
                        </g>}
                    </g>
                </svg>
            </div>
        </div>
    )
}

GenericDiscreteBarTimeseries.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape(
            {
                label: PropTypes.any,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.any,
                        value: PropTypes.number,
                    })
                )
            }
        )
    ),
    overlay: PropTypes.node,
    compareData: PropTypes.arrayOf(
        PropTypes.shape(
            {
                label: PropTypes.any,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.any,
                        value: PropTypes.number,
                    })
                )
            }
        )
    ),
    yAxisOneMin: PropTypes.number,
    yAxisOneMax: PropTypes.number,
    yAxisTwoMin: PropTypes.number,
    yAxisTwoMax: PropTypes.number,
}

