import { useContext, useMemo } from "react"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import { css } from "@emotion/react"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import { colors, timings } from "../../../style/vars"
import LocaleContext from "../../../context/Locale"
import { formatDistanceToNow, fromUnixTime } from "date-fns"

export default function LiveMetricsList({ ...props }) {
    const { datefnsLocale } = useContext(LocaleContext)
    const { metricTypes } = useContext(CovermasterContext)
    const { 
        selectedPosition, 
        selectedMetricType, 
        setSelectedMetricType, 
        liveZoneResults: zoneResults,
        metricAverages: averages,
        timestamps,
    } = useContext(PitchTelemetry)

    const showZoneDetail = useMemo(() => {
        return !!selectedPosition
    }, [selectedPosition])

    const dataToShow = useMemo(() => {
        if (!zoneResults || !averages) return {}
        return showZoneDetail ?
            zoneResults
                .find(zone => zone.posX === selectedPosition.posX && zone.posY === selectedPosition.posY)?.latestTelemetries ?? {}
            :
            averages
    }, [averages, zoneResults, showZoneDetail, selectedPosition])

    const timeMessage = useMemo(() => {
        const noMeasurementsMsg = `No measurements within the last hour`  /* TO DO: translate */
        if (!timestamps) return noMeasurementsMsg

        const allMin = timestamps.allMin ? formatDistanceToNow(
            fromUnixTime(timestamps.allMin),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const allMax = timestamps.allMax ? formatDistanceToNow(
            fromUnixTime(timestamps.allMax),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const selectedMin = timestamps.selectedMin ? formatDistanceToNow(
            fromUnixTime(timestamps.selectedMin),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const selectedMax = timestamps.selectedMax ? formatDistanceToNow(
            fromUnixTime(timestamps.selectedMax),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */

        const selectedEqual = selectedMax === selectedMin
        const allEqual = allMax === allMin

        return selectedPosition ?
            (selectedEqual ? `${selectedMax ?? noMeasurementsMsg}` : `between ${selectedMin} and ${selectedMax}`)
            :
            (allEqual ? `${allMax ?? noMeasurementsMsg}` : `between ${allMin} and ${allMax}`)
        /* TO DO: translate */
    }, [timestamps, datefnsLocale, selectedPosition])

    return <div css={css`max-width: 100%;`}>

        {Object.entries(dataToShow)
            .map(([metricIRI, dataObj]) => {
                const metricType = metricTypes.find(type => type['@id'] === metricIRI)
                return <div
                    key={metricIRI}
                    onClick={() => setSelectedMetricType(metricIRI)}
                    css={css`
                            margin-bottom: 4px;
                            display: flex; 
                            width: 100%; 
                            justify-content: space-between;
                            padding: 0.4em 1em;
                            background: rgba(0,0,0,0.1); 
                            border-radius: 2px;
                            cursor: pointer;
                            transition: background ${timings.responsive}, transform ${timings.smooth};
                            border-top: 1px solid transparent;
                            border-bottom: 1px solid ${selectedMetricType === metricIRI ? colors.main1 : 'transparent'};
                            
                            &:hover {
                                transform: translate(-4px, 0);
                                background: rgba(200,200,200,0.05);
                            }
                        `}>
                    <div css={css`
                        opacity: ${Number.isFinite(dataObj.measurement) ? 1 : 0.3};
                    `}>
                        {metricType?.title}
                    </div>
                    <div css={css`
                        opacity: ${Number.isFinite(dataObj.measurement) ? 1 : 0.3};
                    `}>
                        {dataObj.measurement ?? 'N/A'} {/* To do: translate */}
                    </div>
                </div>
            })
        }

        <div css={css`
            color: ${colors.soft};
            margin-left: 1em;
            margin-top: 1em;
        `}>
            {timeMessage}
            {/* To do: translate */}
        </div>
    </div>
}