import React, { useMemo, useContext } from 'react'
import BarGraph from './BarGraph'
import EventTypeSvg from '../events/EventTypeSvg'
import ReporterContext from '../../context/Reporter'
import useBridge from '../../hooks/useBridge'
import Loader from '../_general/Loader'
import { getGroupsForEvent } from '../../utils/eventUtils'

const getTimeTakenHours = (arr) => {
    if (arr.length === 0) return 0
    const timeTaken = arr.reduce((total, current) => total + (current.timeTaken ?? 0), 0)
    return Math.round(timeTaken / 60 * 10) / 10
}

export default function PitchesEvents({ ...props }) {

    const {
        events,
        compareEvents,
        reporterBusy,
        lineColor1,
        lineColor2,
        showTimeTotals,
    } = useContext(ReporterContext)

    const { data: eventTypes, loading: loadingEventTypes } = useBridge('/api/current/frontend-bridge/event-types')

    const dataSet = useMemo(() => {
        if (!eventTypes) return []
        let types = []
        if (events) {
            [...events, ...(compareEvents || [])].map(event => {
                if (!types.find(type => type.label === event.type)) {

                    const filteredEvents = events.filter(x => x.type === event.type)
                    const filteredCompareEvents = compareEvents?.filter(x => x.type === event.type) || []

                    types.push({
                        label: event.type,
                        labelLocalised: eventTypes.find(x => x.entityShortName === event.type)?.entityShortNameLocalised,
                        data: [
                            {
                                label: "events",
                                value: showTimeTotals ? getTimeTakenHours(filteredEvents) : filteredEvents.length
                            },
                            ...(compareEvents ? [{
                                label: "compareEvents",
                                value: showTimeTotals ? getTimeTakenHours(filteredCompareEvents) : filteredCompareEvents.length
                            }] : []),
                        ]
                    })
                }
            })
        }

        return types.sort((a, b) => {
            // sort alphabetically
            const labelA = a.labelLocalised
            const labelB = b.labelLocalised

            return labelA < labelB ? -1
                : labelA > labelB ? 1
                    : 0
        }).sort((a, b) => {
            // 'player' events go first
            const isPlayer = (event) => getGroupsForEvent(event).includes('performance')
            const playerA = isPlayer(a.label)
            const playerB = isPlayer(b.label)

            return playerA && (!playerB) ? -1 :
                (!playerA) && playerB ? 1 :
                    0
        }

        )

    }, [events, compareEvents, eventTypes, showTimeTotals])

    return (loadingEventTypes ? <Loader /> :
        <BarGraph
            {...props}
            dataSet={dataSet}
            graphStyle={{ opacity: reporterBusy ? 0.25 : 1 }}
            dataRender={
                ({ xPos, yPos, label, bars, groupWidth, index }) => (
                    <g
                        key={index}
                        style={{ transform: `translateX(${xPos + 20}px) translateY(${yPos}px)` }}>
                        {bars.map(
                            ({ label, width, height, xOffset }, index) => (
                                <rect
                                    key={label}
                                    fill={label === "events" ? lineColor1 : lineColor2}
                                    width={width}
                                    height={height}
                                    style={{ transform: `translateX(${xOffset}px) translateY(-${height}px)` }}
                                />
                            )
                        )}

                        <g style={{ transform: `translate(${(groupWidth / 2) - 20}px, 0.5em)` }}>
                            <EventTypeSvg
                                eventType={label}
                                width={40}
                            />
                        </g>
                    </g>
                )
            }
        />
    )
}
