import React, { createContext, useMemo, useState, useContext } from 'react'
import PitchListContext from './PitchList'
import { subDays } from 'date-fns'
import { useCallback } from 'react'
import { useEffect } from 'react'
import tableau from 'tableau-api'
import { isEqual } from 'lodash'
import useTracker from '../hooks/useTracker'
import { format } from 'date-fns'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../utils/matomo'
import useRawV2 from '../hooks/useRawV2'
import ProfileContext from './Profile'

const AnalyticsContext = createContext()

function AnalyticsProvider({ ...props }) {

    const track = useTracker()
    const [profile] = useContext(ProfileContext)

    // this is where all our state goes for the dashboards that are actually loaded
    const [activeDashboards, setActiveDashboards] = useState([])

    // sync last dashboards to localstorage
    useEffect(() => {
        if (activeDashboards.length > 0) {
            localStorage.setItem('lastDashboards', JSON.stringify(activeDashboards.map(d => d.id)))
        }
    }, [activeDashboards])

    const maxCapacity = useMemo(() => {
        return activeDashboards?.length >= 6
    }, [activeDashboards])

    // date stuff
    const defaultStartDate = subDays(new Date(), 31)
    const defaultEndDate = new Date()
    defaultStartDate.setHours(0, 0, 0, 0)
    defaultEndDate.setHours(23, 59, 59, 999)
    const [startDate, setStartDate] = useState(defaultStartDate)  // states for the inputs
    const [endDate, setEndDate] = useState(defaultEndDate)
    const [lastDateFilter, setLastDateFilter] = useState([startDate, endDate])  // state that was last actually applied as a filter

    const isDateUnconfirmed = useMemo(() => {   // if our date differs from the last date, we allow the user to confirm (apply) the filter
        if (startDate === null || endDate === null) return false
        return startDate.getTime() !== lastDateFilter[0].getTime() || endDate.getTime() !== lastDateFilter[1].getTime()
    }, [startDate, endDate, lastDateFilter])

    const syncDateLines = useCallback((id, start, end) => {
        const dash = activeDashboards.find(d => d.id === id)
        const workbook = dash.viz?.getWorkbook()
        if (!workbook) return
        workbook.changeParameterValueAsync(
            'dateLine1',
            start
        )
        workbook.changeParameterValueAsync(
            'dateLine2',
            end
        )
        // WARNING: none of the secondary dashboards currently need this, behavior not accounted for.
        // WARNING: none of the dashboards using their own 'DashDateFilter' currently need this, behavior not accounted for.
    }, [activeDashboards])

    const filterDateGlobal = useCallback(() => {
        setLastDateFilter([startDate, endDate])
        activeDashboards.forEach(dash => {
            // primary dashboards
            if (dash.viz && dash.behavior.globalDateFilter) {
                const sheets = dash.viz.getWorkbook().getActiveSheet().getWorksheets()
                for (const sheet of sheets) {
                    sheet.applyRangeFilterAsync(
                        "Datetime",
                        { min: startDate, max: endDate },
                        window.tableau.FilterUpdateType.REPLACE
                    )
                }
            }
            //secondary dashboards
            if (dash.secondaryDashboard?.viz && dash.secondaryDashboard?.behavior.globalDateFilter) {
                const sheets = dash.secondaryDashboard.viz.getWorkbook().getActiveSheet().getWorksheets()
                for (const sheet of sheets) {
                    sheet.applyRangeFilterAsync(
                        "Datetime",
                        { min: startDate, max: endDate },
                        window.tableau.FilterUpdateType.REPLACE
                    )
                }
            }
            // date lines
            if (dash.behavior.useDateLines) syncDateLines(dash.id, startDate, endDate)
        })

        try {
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['date-select'],
                [MTM_VARIABLES['list-value']]: `${format(startDate, 'yyyy-MM-dd')} - ${format(endDate, 'yyyy-MM-dd')}`
            })
        } catch (e) { console.log(e) }

    }, [activeDashboards, startDate, endDate, syncDateLines])

    const onLoadDateSync = useCallback((id, secondary) => {
        const [appliedStartDate, appliedEndDate] = lastDateFilter
        // we only need to do this if the currently selected date is different from default
        if (defaultStartDate.getTime() === appliedStartDate.getTime() && defaultEndDate.getTime() === appliedEndDate.getTime()) return
        const dash = activeDashboards.find(d => d.id === id)
        if (!dash?.viz || !dash.behavior.globalDateFilter) return

        // check if the main dashboard is requesting this, or a secondary one
        const sheets = !secondary ?
            dash.viz.getWorkbook().getActiveSheet().getWorksheets() :
            dash.secondaryDashboard.viz.getWorkbook().getActiveSheet().getWorksheets()

        for (const sheet of sheets) {
            sheet.applyRangeFilterAsync(
                "Datetime",
                { min: appliedStartDate, max: appliedEndDate },
                window.tableau.FilterUpdateType.REPLACE
            )
        }
        // date lines
        if (dash.behavior.useDateLines) syncDateLines(dash.id, appliedStartDate, appliedEndDate)
    }, [activeDashboards, lastDateFilter, syncDateLines])

    const { pitches: pData } = useContext(PitchListContext)

    // for admins, fetch a specific club's pitches for demo purposes if the setting was enabled
    const storedClub = localStorage.getItem('adminAnalyticsClub')
    const [adminPitches] = useRawV2(
        (profile?.admin && storedClub) ?
            `/api/current/clubs/${storedClub}/pitches`
            : undefined
    )

    const [pitches, defaultPitches, showDemoShortcut] = useMemo(() => {

        const adminPitchIds = adminPitches?.['hydra:member'].map(p => p.id)

        const pitches = storedClub ?
            pData.filter(p => adminPitchIds?.includes(p.id))
            : pData

        if (!pitches) return []
        
        return [
            pitches.map(p => ({
                id: p.id,
                name: p.name,
                color: p.pitchColor ? p.pitchColor : 'transparent'
            })),

            pitches.map(p => p.id),

            !!pitches?.find(pitch => pitch.id === Number(process.env.DEMO_PITCH_ID)),
        ]
    }, [pData, adminPitches])

    // pitch id's that make up our pitch selector control
    const [filteredPitches, setFilteredPitches] = useState([])

    // make sure on load, all pitches are selected automatically after they are available, even if we refresh (and context does not have our values yet)
    useEffect(() => {
        if (defaultPitches) setFilteredPitches(defaultPitches)
    }, [defaultPitches])

    const filterPitchesGlobal = useCallback(() => {
        activeDashboards.forEach(dash => {
            if (!dash.viz || !dash.behavior.globalPitchFilter) return
            const sheets = dash.viz.getWorkbook().getActiveSheet().getWorksheets()
            // if sheets are specified, filter  only those. else filter all sheets
            const specifiedSheets = dash.behavior.globalPitchFilterSheetNames
            for (const sheet of sheets) {
                if (specifiedSheets && !specifiedSheets.includes(sheet.getName())) continue
                sheet.applyFilterAsync(
                    "Pitch Id",
                    filteredPitches,
                    window.tableau.FilterUpdateType.REPLACE
                )
            }
        })
        // WARNING: none of the secondary dashboards currently need this, 
        // so no filtering behavior of secondaries is account for here yet
    }, [filteredPitches, activeDashboards])

    useEffect(() => filterPitchesGlobal(), [filteredPitches])

    // on dashboard load
    const onLoadPitchSync = useCallback((id, secondary) => {
        // if all pitches are selected, don't do anything
        if (isEqual(defaultPitches, filteredPitches)) return
        const dash = activeDashboards.find(d => d.id === id)

        // check if it is the main dashboard is requesting this, or a secondary one
        let sheets
        let specifiedSheets
        if (!secondary) {
            if (!dash?.viz || !dash.behavior.globalPitchFilter) return
            sheets = dash.viz.getWorkbook().getActiveSheet().getWorksheets()
            specifiedSheets = dash.behavior.globalPitchFilterSheetNames
        } else {
            if (!dash?.secondaryDashboard?.viz || !dash.secondaryDashboard.behavior.globalPitchFilter) return
            sheets = dash.secondaryDashboard.viz.getWorkbook().getActiveSheet().getWorksheets()
            specifiedSheets = dash.secondaryDashboard.behavior.globalPitchFilterSheetNames
        }

        for (const sheet of sheets) {
            if (specifiedSheets && !specifiedSheets.includes(sheet.getName())) continue
            sheet.applyFilterAsync(
                "Pitch Id",
                filteredPitches,
                window.tableau.FilterUpdateType.REPLACE
            )
        }
    }, [activeDashboards, filteredPitches])

    //info modal
    const [infoModal, setInfoModal] = useState('') // info text

    //download modal
    const [downloadTarget, setDownloadTarget] = useState(null) // viz to download from

    const setParameter = useCallback((id, tableauVariableName, value, isSecondary) => {
        const dash = activeDashboards.find(d => d.id === id)
        const workbook = !isSecondary ? dash.viz?.getWorkbook() : dash.secondaryDashboard.viz?.getWorkbook()
        if (!workbook) return
        workbook.changeParameterValueAsync(
            tableauVariableName,
            value
        )
        // if this was a secondary, we are done.
        if (isSecondary) return

        // if this was primary, and secondary dashboard listens to primary's parameter, apply it there as well
        const linkedParameters = dash.secondaryDashboard?.behavior.linkedParameters
        if (!linkedParameters?.map(p => p.name).includes(tableauVariableName)) return 

        const secondaryWorkbook = dash.secondaryDashboard.viz.getWorkbook()
        if (!secondaryWorkbook) return
        secondaryWorkbook.changeParameterValueAsync(
            tableauVariableName,
            value
        )
    }, [activeDashboards])

    const setFilter = useCallback((id, tableauVariableName, value, isSecondary) => {
        const dash = activeDashboards.find(d => d.id === id)
        // check if control belongs to primary / secondary dashboard
        const viz = !isSecondary ? dash.viz : dash.secondaryDashboard.viz
        const filters = !isSecondary ? dash.behavior.filters : dash.secondaryDashboard.behavior.filters

        // check if this value uses range filter or regular filter (function)
        const isRangeFilter = typeof value === 'object' &&
            Object.keys(value).includes('min') &&
            Object.keys(value).includes('max')

        if (!viz || !filters) return
        // filter that dashboard:
        // find info about this specific filter
        const { sheetNames } = filters.find(f => f.name === tableauVariableName)
        const sheets = viz.getWorkbook().getActiveSheet().getWorksheets()
        if (!sheets) return
        // if sheetnames to filter are specified, filter just those. else filter all sheets
        for (const sheet of sheets) {
            if (sheetNames && !sheetNames.includes(sheet.getName())) continue
            isRangeFilter ?
                sheet.applyRangeFilterAsync(tableauVariableName, value, window.tableau.FilterUpdateType.REPLACE) :
                sheet.applyFilterAsync(tableauVariableName, value, window.tableau.FilterUpdateType.REPLACE)
        }

        // if this was a secondary, we are done.
        if (isSecondary) return

        // if this was primary, and secondary dashboard listens to primary's filters, apply it there as well
        const linkedFilters = dash.secondaryDashboard?.behavior.linkedFilters
        if (!linkedFilters?.map(f => f.name).includes(tableauVariableName)) return
        const { linkedSheetNames } = linkedFilters.find(f => f.name === tableauVariableName)
        const secondarySheets = dash.secondaryDashboard.viz.getWorkbook().getActiveSheet().getWorksheets()
        if (!secondarySheets) return
        for (const sheet of secondarySheets) {
            if (linkedSheetNames && !linkedSheetNames.includes(sheet.getName())) continue
            isRangeFilter ?
                sheet.applyRangeFilterAsync(tableauVariableName, value, window.tableau.FilterUpdateType.REPLACE) :
                sheet.applyFilterAsync(tableauVariableName, value, window.tableau.FilterUpdateType.REPLACE)
        }
    }, [activeDashboards])

    const onLoadCreateUI = useCallback(async (id, secondary) => {
        const dash = activeDashboards.find(d => d.id === id)
        const workbook = !secondary ? dash?.viz?.getWorkbook() : dash?.secondaryDashboard?.viz?.getWorkbook()
        const dashFilters = !secondary ? dash.behavior.filters : dash.secondaryDashboard.behavior.filters
        const dashParams = !secondary ? dash.behavior.parameters : dash.secondaryDashboard.behavior.parameters
        if (!workbook) return
        //filters
        const filtersData = []
        if (!!dashFilters) {
            for (const filter of dashFilters) {
                let type
                const options = []
                if (filter.dataSource === 'pitches') {
                    // use our pitches from frontend, same as global pitch control
                    type = 'list'
                    options.push(...pitches.map(p => ({
                        value: p.id,
                        label: p.name
                    })))
                } else if (filter.dataSource === 'hardcode') {
                    type = 'list'
                    options.push(...filter.values?.map(v => ({
                        value: v,
                        label: v
                    })))
                } else if (filter.dataSource === 'dates') {
                    type = 'date'
                }

                if (filter.addDefaultOption) options.unshift({
                    value: null,
                    label: filter.addDefaultOption
                })

                filtersData.push({
                    name: filter.name,
                    type: type,
                    options: options,
                    flexOrder: filter.flexOrder,
                    selectedOptionValue: filter.selectedOption,
                    addDefaultOption: filter.addDefaultOption
                })
            }
        }
        //parameters
        const paramsData = []
        if (!!dashParams) {
            // get the specified parameters from tableau
            const workbookParams = await workbook.getParametersAsync() // tableau data
            const paramData = workbookParams.filter(p => dashParams.map(dP => dP.name).includes(p.getName()))

            // (apparently bools are also 'list' type)
            for (const param of paramData) {
                const type = param.getAllowableValuesType()
                const selectedValue = param.getCurrentValue().value
                const name = param.getName()
                if (type === 'list') {
                    const optionValues = param.getAllowableValues().map(p => p.value)
                    paramsData.push({
                        name: name,
                        flexOrder: dashParams.find(p => p.name === name).flexOrder,
                        label: dashParams.find(p => p.name === name).label,
                        type: type,
                        values: optionValues,
                        selectedValue: selectedValue,
                        conditionalRenderingRules: dashParams.find(p => p.name === name).conditionalRenderingRules
                    })
                } else if (type === 'range') {
                    const stepSize = param.getStepSize()
                    const minVal = param.getMinValue().value
                    const maxVal = param.getMaxValue().value
                    paramsData.push({
                        name: name,
                        flexOrder: dashParams.find(p => p.name === name).flexOrder,
                        label: dashParams.find(p => p.name === name).label,
                        type: type,
                        selectedValue: selectedValue,
                        stepSize: stepSize,
                        minVal: minVal,
                        maxVal: maxVal,
                        conditionalRenderingRules: dashParams.find(p => p.name === name).conditionalRenderingRules
                    })
                }
            }
        }
        // update state
        setActiveDashboards(current => {
            return current.map(d => {
                if (d.id !== id) return d

                if (!secondary) {
                    if (!!dashFilters) dash.ui.filters = filtersData
                    if (!!dashParams) dash.ui.parameters = paramsData
                } else {
                    if (!!dashFilters) dash.secondaryDashboard.ui.filters = filtersData
                    if (!!dashParams) dash.secondaryDashboard.ui.parameters = paramsData
                }

                return dash
            })
        })
    }, [activeDashboards, pitches])

    const toggleSecondaryDash = useCallback((id) => {
        setActiveDashboards(current => {
            return current.map(d => {
                if (d.id !== id) return d
                d.secondaryDashboard.isHidden = !d.secondaryDashboard.isHidden
                return d
            })
        })
    }, [activeDashboards])

    return (
        <AnalyticsContext.Provider
            value={{
                dates: {
                    startDate,
                    setStartDate,
                    endDate,
                    lastDateFilter,
                    setEndDate,
                    isDateUnconfirmed,
                    filterDateGlobal,
                    onLoadDateSync,
                    showDemoShortcut
                },
                pitches: {
                    pitches,
                    filteredPitches,
                    setFilteredPitches,
                    filterPitchesGlobal,
                    onLoadPitchSync,
                },
                dashboards: {
                    activeDashboards,
                    setActiveDashboards,
                    maxCapacity,
                    infoModal,
                    setInfoModal,
                    downloadTarget,
                    setDownloadTarget,
                    setParameter,
                    setFilter,
                    onLoadCreateUI,
                    toggleSecondaryDash,
                }
            }}
            {...props}
        />
    )
}

export default AnalyticsContext
export { AnalyticsProvider }