import React, { useMemo, useContext, useState, useCallback } from 'react'
import useRawApiState from '../../hooks/useRawApiState'
import { closestIndexTo, getUnixTime, isWithinInterval } from 'date-fns'
import ZoneResults from '../test/ZoneResults'
import HealthEffectsMap from '../physicalAssessment/HealthEffectsMap'
import { css } from '@emotion/react'
import FallbackMessage from '../_general/FallbackMessage'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import EffectsList from '../physicalAssessment/EffectsList'
import Orientation from '../test/Orientation'
import PropTypes from 'prop-types'
import Parameter from '../test/Parameter'
import InstructionSteps from '../test/InstructionSteps'
import { BodyPartProvider } from '../../context/BodyPart'
import ReporterContext from '../../context/Reporter'
import GridModeSwitcher from '../test/GridModeSwitcher'
import { filterChartData } from './util'
import { getDateKey } from './util'
import { useIntl, FormattedMessage } from 'react-intl'
import { SurfaceLayerProvider } from '../../context/SurfaceLayer'
import PreferredViewContext from '../../context/PreferredView'
import PitchEffects from '../physicalAssessment/PitchEffects'
import { SurfaceHighlightsProvider } from '../../context/SurfaceHighlights'

function FieldGraph({
    testCategory1,
    testCategory2,
    modelActive,
    ...props
}) {

    const { formatMessage } = useIntl()

    const {
        pitchId,
        compareTo,
        tests1,
        compareData,
        lineColor1,
        lineColor2,
        highlightDate: selectedDate,
        timeAxis,
    } = useContext(ReporterContext)

    const { data: pitch1 } = useRawApiState(
        pitchId && `/api/current/pitches/${pitchId}`,
        {},
        [pitchId]
    )

    const { data: pitch2 } = useRawApiState(
        compareTo?.pitch && `/api/current/pitches/${compareTo?.pitch}`,
        {},
        [compareTo]
    )

    const { comparing = false, comparePitch, compareCategory } = useMemo(
        () => {
            return compareTo?.pitch ? {
                comparing: true,
                comparePitch: pitch2,
                compareCategory: testCategory1
            }
                : {
                    comparing: false,
                    comparePitch: pitch1,
                    compareCategory: testCategory2
                }
        }
        , [pitch1, pitch2, testCategory1, testCategory2, compareTo])

    const [{ multipleTests1Error, multipleTests2Error }, setErrorState] = useState({ multipleTests1Error: false, multipleTests2Error: false })

    const handleErrorState = useCallback((testNumber, hasError) => {
        setErrorState((prev) => ({
            ...prev,
            [`multipleTests${testNumber}Error`]: hasError,
        }))
    }, [])

    const getClosestTest = useCallback((tests, selectedDate) => {
        if (!tests || tests.length === 0) return null
        const closestIndex = closestIndexTo(
            selectedDate,
            tests.map(({ analysisDate }) => new Date(analysisDate * 1000))
        )
        return tests[closestIndex]
    }, [])

    const isTestValidForDisplay = useCallback(({ selectedUnix, closestTest, tests, selectedDateKey }) => {

        const isWithin12h = isWithinInterval(selectedUnix, {
            start: closestTest.analysisDate - 12 * 60 * 60,
            end: closestTest.analysisDate + 12 * 60 * 60,
        })

        const isWithinRange = isWithinInterval(selectedUnix, {
            start: tests[tests.length - 1].analysisDate,
            end: tests[0].analysisDate,
        })

        const dayHasMultipleTests =
            timeAxis === 'discrete-day' &&
            tests.filter(
                (t) => getDateKey(new Date(t.analysisDate * 1000)) === selectedDateKey
            ).length > 1

        return { isWithin12h, isWithinRange, dayHasMultipleTests }
    }, [timeAxis])

    const getTestToShow = useCallback(({ closestTest, tests, testNumber }) => {

        if (!tests || !tests.length || !closestTest) {
            handleErrorState(testNumber, false)
            return undefined
        }

        const selectedUnix = getUnixTime(selectedDate)
        const selectedDateKey = getDateKey(selectedDate)
        const { isWithin12h, isWithinRange, dayHasMultipleTests } = isTestValidForDisplay({
            selectedUnix,
            closestTest,
            tests,
            selectedDateKey,
        })

        if (timeAxis === 'discrete-day') {

            if (dayHasMultipleTests) {
                handleErrorState(testNumber, true)
                return undefined
            }
            handleErrorState(testNumber, false)
            const closestIsOnSameDay = selectedDateKey === getDateKey(new Date(closestTest.analysisDate * 1000))
            return closestIsOnSameDay ? closestTest : undefined

        } else if (timeAxis === 'continuous') {
            handleErrorState(testNumber, false)
            if (tests.length === 1) return isWithin12h ? closestTest : undefined
            return isWithinRange ? closestTest : undefined

        } else { return undefined }

    }, [timeAxis, selectedDate])

    const [test1, test2] = useMemo(() => {
        if (!selectedDate) return [undefined, undefined]

        const filteredTests1 = filterChartData(tests1)
        const filteredTests2 = filterChartData(compareData)
        const closestTest1 = getClosestTest(filteredTests1, selectedDate)
        const closestTest2 = getClosestTest(filteredTests2, selectedDate)

        return [
            getTestToShow({
                closestTest: closestTest1,
                tests: filteredTests1,
                testNumber: 1,
            }),
            getTestToShow({
                closestTest: closestTest2,
                tests: filteredTests2,
                testNumber: 2,
            }),
        ]
    }, [tests1, compareData, selectedDate, getTestToShow])

    const test1HasParameters = useMemo(() => {
        return !!test1?.parameterResults?.length
    }, [test1])

    const test2HasParameters = useMemo(() => {
        return !!test2?.parameterResults?.length
    }, [test2])

    const { preferredView, PREFERRED_VIEW_TYPES } = useContext(PreferredViewContext)

    const isPerformanceMode = useMemo(() => {
        return preferredView === PREFERRED_VIEW_TYPES.performance
    }, [preferredView, PREFERRED_VIEW_TYPES])

    return (
        <BodyPartProvider><SurfaceLayerProvider>
            <div css={css`
                display: flex;
                position: relative;
                justify-content: flex-start;
                max-height: 100%;
                height: 100%;

                @media screen and (max-width: 800px) {
                    display: grid;
                    grid-template: 1fr 1fr / 1fr 1fr;
                    gap: 1em 0em;

                    > div {
                        width: 100%;
                    }
                }
            `}
            >
                {
                    (!test1 && !test2 && !multipleTests1Error && !multipleTests2Error) ? (
                        <FallbackMessage style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '2.5em',
                            width: '100%',
                            height: '100%',
                            position: 'absolute'
                        }}>
                            <FormattedMessage id='noDataAvailable' />
                        </FallbackMessage>
                    ) : null
                }
                <div css={{ height: '100%', maxHeight: '100%', width: '50%', position: 'relative' }}>
                    {test1 && <>
                        {testCategory1?.testType === "laboratory"
                            ?
                            <div css={{ height: '100%', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                {test1.parameterResults?.some(
                                    parameterResult => (parameterResult.measurement !== null)
                                )
                                    ? test1.parameterResults?.map(parameterResult => (
                                        parameterResult.measurement !== null && (
                                            <Parameter
                                                style={{ fontSize: '0.875em' }}
                                                key={parameterResult['@id']}
                                                parameterResult={parameterResult}
                                                showOptimum
                                            />
                                        )
                                    ))
                                    : <InstructionSteps title={formatMessage({ id: 'noDataAvailable' })} />
                                }
                            </div>
                            :
                            <>
                                {(!modelActive && test1?.zoneResults) && <>
                                    <ZoneResults
                                        gridId={test1.id}
                                        backgroundType={pitch1?.pitchBackground || pitch1?.club?.sport}
                                        css={css`pointer-events: none; min-height: 100%; width: 100%; background-color: #333333`}
                                        zoneResults={test1.zoneResults}
                                        isPercentageTemplate={test1.testCategory?.pitchTemplate?.templateType === 'percentage'}
                                        decimals={test1.testCategory?.allowedDecimals}
                                        getZoneBackendColor={(zone) => (zone.rating?.assessment?.frontendCode)}
                                    />
                                    <Orientation
                                        css={style.compass}
                                        heading={pitch1?.orientation}
                                    />
                                </>}
                                {isPerformanceMode ?
                                    !!(modelActive && test1.physicalImpact) &&
                                    <HealthEffectsMap
                                        style={{ height: '100%', maxHeight: '100%' }}
                                        physicalAssessments={test1.physicalImpact || []}
                                    />
                                    :
                                    !!(modelActive && test1.pitchImpact) &&
                                    <SurfaceHighlightsProvider pitchImpacts={test1?.pitchImpact || []}>
                                        <PitchEffects />
                                    </SurfaceHighlightsProvider>
                                }

                            </>}
                    </>}
                    {multipleTests1Error &&
                        <FallbackMessage style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '2em',
                            height: '100%',
                        }}>
                            <FormattedMessage id='dayContainsMultipleTests' />
                        </FallbackMessage>}
                </div>

                <div css={{ height: '100%', maxHeight: '100%', width: '50%', position: 'relative', padding: "0 1em" }}>
                    {
                        testCategory1 && test1 &&
                        <>
                            <div css={style.testSelectionRow}>
                                {!modelActive && !test1HasParameters && <GridModeSwitcher />}
                                <div css={style.testSelectionItem}>
                                    <TestCategoryIcon
                                        iconName={testCategory1.icon}
                                        color={lineColor1}
                                        css={css`font-size: 2em;`}
                                    />
                                </div>
                                <div css={css`
                                    display: flex; 
                                    flex-direction: column;
                                    justify-content: space-between; 
                                    align-self: stretch; 
                                    padding: 2px;
                                `}>
                                    <div><b>{testCategory1.name}</b></div>
                                    {comparing && <div><b>{pitch1?.name}</b></div>}
                                </div>
                            </div>
                            {test1 &&
                                <div css={css`
                                    padding-top: 1em;
                                    > div {
                                        margin-top: 0;   
                                    }
                                `}>
                                    {isPerformanceMode ?
                                        <EffectsList
                                            effects={test1.physicalImpact || []}
                                            css={style.EffectsList}
                                            type='player'
                                        />
                                        :
                                        <EffectsList
                                            effects={test1.pitchImpact || []}
                                            css={style.EffectsList}
                                            type='pitch'
                                        />
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
                <div css={{ height: '100%', maxHeight: '100%', width: '50%', position: 'relative' }}>
                    {test2 && <>
                        {
                            testCategory2?.testType === "laboratory"
                                ?
                                <div css={{ height: '100%', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    {test2.parameterResults?.some(
                                        parameterResult => (parameterResult.measurement !== null)
                                    )
                                        ? test2.parameterResults.map(parameterResult => (
                                            parameterResult.measurement !== null && (
                                                <Parameter
                                                    style={{ fontSize: '0.875em' }}
                                                    key={parameterResult['@id']}
                                                    parameterResult={parameterResult}
                                                    showOptimum
                                                />
                                            )
                                        ))
                                        : <InstructionSteps title={formatMessage({ id: 'noDataAvailable' })} />
                                    }
                                </div>
                                :
                                <>
                                    {
                                        (!modelActive && test2.zoneResults) &&
                                        <>
                                            <ZoneResults
                                                gridId={test2.id}
                                                backgroundType={
                                                    pitch2?.pitchBackground || pitch2?.club?.sport ||
                                                    pitch1?.pitchBackground || pitch1?.club?.sport
                                                }
                                                css={css`point-events: none; min-height: 100%; width: 100%; background-color: #333333`}
                                                zoneResults={test2.zoneResults}
                                                isPercentageTemplate={test2.testCategory?.pitchTemplate?.templateType === 'percentage'}
                                                decimals={test2.testCategory?.allowedDecimals}
                                                getZoneBackendColor={(zone) => (zone.rating?.assessment?.frontendCode)}
                                            />
                                            <Orientation
                                                css={style.compass}
                                                heading={pitch2?.orientation}
                                            />
                                        </>
                                    }
                                    {isPerformanceMode ?
                                        !!(modelActive && test2.physicalImpact) &&
                                        <HealthEffectsMap
                                            style={{ height: '100%', maxHeight: '100%' }}
                                            physicalAssessments={test2.physicalImpact || []}
                                        />
                                        :
                                        !!(modelActive && test2.pitchImpact) &&
                                        <SurfaceHighlightsProvider pitchImpacts={test2.pitchImpact || []}>
                                            <PitchEffects />
                                        </SurfaceHighlightsProvider>
                                    }
                                </>
                        }
                    </>}
                    {multipleTests2Error &&
                        <FallbackMessage style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '2em',
                            height: '100%',
                        }}>
                            <FormattedMessage id='dayContainsMultipleTests' />
                        </FallbackMessage>}
                </div>
                <div css={{ height: '100%', maxHeight: '100%', width: '50%', position: 'relative', padding: "0 1em" }}>
                    {
                        compareCategory && test2 &&
                        <>
                            <div css={style.testSelectionRow}>
                                {!modelActive && !test2HasParameters && <GridModeSwitcher />}
                                <div css={style.testSelectionItem}>
                                    <TestCategoryIcon
                                        iconName={compareCategory.icon}
                                        color={lineColor2}
                                        css={css`font-size: 2em;`}
                                    />
                                </div>
                                <div css={css`
                                    display: flex; 
                                    flex-direction: column;
                                    justify-content: space-between; 
                                    align-self: stretch; 
                                    padding: 2px;
                                `}>
                                    <div><b>{compareCategory.name}</b></div>
                                    {comparing && <div><b>{comparePitch?.name}</b></div>}
                                </div>
                            </div>
                            {test2 &&
                                <div css={css`
                                    padding-top: 1em;
                                    > div {
                                        margin-top: 0;   
                                    }
                                `}>
                                    {isPerformanceMode ?
                                        <EffectsList
                                            effects={test2.physicalImpact || []}
                                            css={style.EffectsList}
                                            type='player'
                                        />
                                        :
                                        <EffectsList
                                            effects={test2.pitchImpact || []}
                                            css={style.EffectsList}
                                            type='pitch'
                                        />
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </SurfaceLayerProvider></BodyPartProvider>
    )
}

FieldGraph.propTypes = {
    testCategory1: PropTypes.object,
    testCategory2: PropTypes.object,
    modelActive: PropTypes.bool
}


export default FieldGraph

const style = {
    testSelectionRow: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;

        b {
            margin-top: 0;
            margin-left: .5em;
        }
    `,
    testSelectionItem: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
    width: 3em;
    height: 3em;
    background-color: #1B1C1F;
`,
    EffectsList: css`
    height: calc(100% - 4em);
    margin-top: 1em;
`,
    compass: css`
    position: absolute;
    height: 2em;
    width: 2em;
    bottom: -.5em;
    left: calc(50% - 1em);
    background-color: rgba(44,52,58);
    box-shadow: none;
    `,
}
