import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { format, parse } from 'date-fns'
import DatePicker from './DatePicker'
import { colors, timings } from '../../style/vars'
import isIos from '../../utils/isIos'
import { ReactSVG } from 'react-svg'
import calendarIcon from '../../assets/icons/nav/calendar.svg'
import Modal from '../_layout/Modal'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'

const style = {
    cont: css`
        color: ${colors.white};
        background-color: transparent;
        border: none;
        border-bottom: 0.0625em solid rgba(224,244,244,0.38);
        padding: 1em 0;
        font-size: 1.125em;
        outline: none;
        transition: all ${timings.fast} ease;
        z-index: 1;
        position: relative;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
        height: 3.36em;
        cursor: pointer;
    `,
}

export default function FancyDatePicker({
    date,
    onDateChange,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false)
    const showIosNativePicker = useMemo(() => (isIos()), [])
    const track = useTracker()
    const { formatMessage } = useIntl()
    const { datefnsLocale } = useContext(LocaleContext)

    const handleNativeDateChange = useCallback((e) => {
        try {
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['date-select'],
                [MTM_VARIABLES['list-value']]: format(date, 'yyyy-MM-dd')
            })
            const newDate = parse(e.target.value)
            const prevHours = date?.getHours()
            const prevMins = date?.getMinutes()
            if (prevHours === undefined || prevMins === undefined) return newDate
            newDate.setHours(prevHours, prevMins) // maintain previous date's time
            onDateChange(newDate)
        } catch { /*eslint-disable*/ }
    }, [date, onDateChange])

    const handleCustomDateChange = useCallback((newDate) => {
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['date-select'],
            [MTM_VARIABLES['list-value']]: format(newDate, 'yyyy-MM-dd')
        })

        const prevHours = date?.getHours()
        const prevMins = date?.getMinutes()
        if (prevHours === undefined || prevMins === undefined) return newDate
        newDate.setHours(prevHours, prevMins) // maintain previous date's time
        onDateChange(newDate)
        setIsOpen(false)
    }, [date, onDateChange, setIsOpen])

    return (
        showIosNativePicker
            ?
            <input
                value={format(date, 'yyyy-MM-dd')}
                onChange={handleNativeDateChange}
                css={style.cont}
                type='date'
                {...props}
            />
            :
            <div>
                <div css={style.cont} {...props} onClick={() => setIsOpen(val => !val)}>
                    <div css={css`display: flex; align-items: center;`}>
                        <span css={css`flex-grow: 1;text-align: center; color: #07D0A5;`}>
                            {format(date, 'PP', { locale: datefnsLocale })}
                        </span>
                        <ReactSVG src={calendarIcon} css={css`
                            width: 20px;
                            fill: #07D0A5;
                            position: absolute;
                            right: 18px;

                            @media screen and (max-width: 600px) {
                                display: none;
                            }
                        `} />
                    </div>
                </div>
                {isOpen &&
                    <Modal
                        onClickClose={() => { setIsOpen(false) }}
                        header={formatMessage({ id: 'selectADate' })}
                        css={css`width: min(95%, 30em); height: 570px; font-size: 1.4em; padding: 0;`}
                    >
                        <DatePicker
                            onDayClick={handleCustomDateChange}
                            selected={date ?? undefined}
                            defaultMonth={date}
                        />
                    </Modal>
                }
            </div>
    )
}

FancyDatePicker.propTypes = {
    date: PropTypes.objectOf(Date), // a date object
    onDateChange: PropTypes.func,
}