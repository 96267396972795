const colorMap = {
    positive: {
        severe: '#00FF63',
        light: '#02F2FF',
    },
    negative: {
        severe: '#ef664e',
        light: '#fec400',
    },
}

const fallbackColor = 'rgba(255,255,255,50)'

export default function getPhysicalAssessmentColor(physicalAssessment) {
    if (!physicalAssessment?.healthEffect) return
    const { healthEffect: { negativeEffect }, severity } = physicalAssessment
    return colorMap[negativeEffect ? 'negative' : 'positive'][severity] || fallbackColor
}

export function getPitchEffectColor(pitchEffect) {
    if (!pitchEffect?.pitchEffect) return
    const { pitchEffect: { negativeEffect }, severity } = pitchEffect
    return colorMap[negativeEffect ? 'negative' : 'positive'][severity] || fallbackColor
}
