import React, { useContext } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { colors, timings } from '../../style/vars'
import LocaleContext from '../../context/Locale'

const style = {
    picker: css`
        display: flex;
        justify-content: center;

        .rdp-day {
            color: ${colors.soft};
            border: 0.2em solid #292E33;
            transition: all ${timings.responsive} ease;

            > button {
                padding: 0em 0.8em;
                border-radius: unset;
                width: 100%;
                background: #32393F;
                transition: background, color ${timings.responsive} ease;

                @media screen and (max-width: 800px) {
                    padding: 0;
                }

                &:hover:not([disabled]) {
                    background: ${colors.main1};
                    color: black;
                }
            }
        }

        .rdp-selected {
            > button {
                border: 2px solid ${colors.main1};
            }
        }

        .rdp-month {
            width: 100%;
            table {
                width: 100%;
            }
        }

        .rdp-months {
            gap: unset;
        }

        .rdp-month_caption {
            width: 100%;
            display: flex;
            justify-items: center;
            position: relative;
            background: #32393F;

            > span {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        .rdp-nav {
             width: 100%;
             display: flex;
             gap: 0.2em;
             position: unset;

             > button {
                flex-grow: 1;
                background: transparent;
                padding: 0;
                transition: background, color ${timings.responsive} ease;

                &:hover:not([disabled]) {
                    background: ${colors.main1};
                    color: black;
                }

                > svg {
                    fill: ${colors.soft};
                }
             }
        }
    `,
}

function DatePicker({
    defaultMonth,
    selected,
    onDayClick,
    ...props
}) {
    const { datefnsLocale, weekStart } = useContext(LocaleContext)

    return (
        <DayPicker
            mode='single'
            locale={datefnsLocale}
            weekStartsOn={weekStart}
            css={style.picker}
            onDayClick={(date) => { onDayClick(date) }}
            defaultMonth={defaultMonth}
            selected={selected}
            {...props}
        />
    )
}

DatePicker.propTypes = {
    onDayClick: PropTypes.func,
}

export default DatePicker
