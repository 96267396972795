import React, { createContext, useState, useCallback, useEffect } from 'react'

const IntegrationsContext = createContext()

function IntegrationsProvider({ ...props }) {

    const integrationFlagNames = [
        'covermaster',
    ]

    const [integrationFlags, setIntegrationFlags] = useState(
        localStorage.getItem('integration-flags') ?
            JSON.parse(localStorage.getItem('integration-flags'))
            : {}
    )

    useEffect(() => {
        if (integrationFlags) {
            localStorage.setItem('integration-flags', JSON.stringify(integrationFlags))
        }
    }, [integrationFlags])

    const isActive = useCallback((integration) => {
        return !!integrationFlags[integration]
    }, [integrationFlags])

    const activate = useCallback((integration) => {
        if (!integrationFlagNames.includes(integration)) return
        setIntegrationFlags(prev => ({
            ...prev,
            [integration]: true
        }))
    }, [])

    const deActivate = useCallback((integration) => {
        if (!integrationFlagNames.includes(integration)) return
        setIntegrationFlags(prev => ({
            ...prev,
            [integration]: false
        }))
    }, [])

    const toggle = useCallback((integration) => {
        if (!integrationFlagNames.includes(integration)) return
        setIntegrationFlags(prev => ({
            ...prev,
            [integration]: !prev[integration]
        }))
    }, [integrationFlags])

    return (
        <IntegrationsContext.Provider
            {...props}
            value={{
                integrationFlags,
                isActive,
                activate,
                deActivate,
                toggle,
            }}
        />
    )
}

export default IntegrationsContext
export { IntegrationsProvider }