import { css } from "@emotion/react"
import PageSubtitle from "../../_general/PageSubtitle"
import { useContext } from "react"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import ZoneResults from "../../test/ZoneResults"
import Orientation from "../../test/Orientation"
import SwitcherSimple from "../../_control/SwitcherSimple"
import GridModeSwitcher from "../../test/GridModeSwitcher"
import LiveMetricsList from "./LiveMetricsList"

export default function LiveTelemetrySection({ ...props }) {

    const { 
        titleMessage,
        pitch,
        pitchBg,
        liveZoneResults,
        handleSelectPosition,
        coverCss,
        isCoverOn,
        isUnderCover,
        setIsUnderCover,
    } = useContext(PitchTelemetry)

    return <div {...props}>

        <PageSubtitle css={css`grid-column: span 2; margin: 0; font-size: 1.5em;`}>
            {titleMessage}{/* TO DO: translate */}
        </PageSubtitle>

        <div>
            <ZoneResults
                gridId={pitch['@id']}
                backgroundType={pitchBg}
                zoneResults={liveZoneResults}
                decimals={2}
                getZoneBackendColor={(zone) => zone.assessment}
                enableClicks={true}
                onClickZone={(zone) => handleSelectPosition(zone)}
                css={[css`height: min-content;`, coverCss]}
            />

            <div css={style.belowGrid}>
                <Orientation
                    heading={pitch?.orientation}
                    css={style.compass}
                />
                <div css={css`opacity: ${isCoverOn ? 1 : 0.5};`}>
                    {isCoverOn ? 'Cover is on' : 'No cover'}{/* to do: translate */}
                </div>
                <SwitcherSimple
                    checked={isUnderCover}
                    onClick={() => {
                        handleSelectPosition()
                        setIsUnderCover(curr => !curr)
                    }}
                    checkedLabel={'under'} /* TO DO: translate */
                    unCheckedLabel={'over'} /* TO DO: translate */
                    buttonCenter={true}
                    css={style.toggle}
                />
                <GridModeSwitcher />
            </div>
        </div>
        <LiveMetricsList />
    </div>
}

const style = {
    belowGrid: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0,0,0,0.1); 
    padding: 0.3em 1em;  
        margin-top: 0.5em;
        gap: 1em;
    `,
    compass: css`
        background: transparent; 
        width: min-content;
        height: min-content; 
    `,
    toggle: css`
        border-radius: 5px;
        width: max-content;
        white-space: nowrap;
        place-self: center;
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;
        height: min-content;
    `,
}