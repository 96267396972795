import { useContext, useEffect, useMemo, useState } from "react"
import useLdjsonApi from "./useLdjsonApi"
import ClubContext from "../context/Club"
import useRawApiState from "./useRawApiState"
import PitchListContext from "../context/PitchList"
import { groupBy, uniqBy } from "lodash"


export default function useClubVenuePitches() {
    const { get } = useLdjsonApi()
    const [club, { busy: clubBusy }] = useContext(ClubContext)
    const { data: logo } = useRawApiState(club?.logo)
    const { defaultOrderPitches: pitchNameIdList } = useContext(PitchListContext)

    const [venues, setVenues] = useState()
    const [venuesBusy, setVenuesBusy] = useState()

    const pitches = useMemo(() => (uniqBy(club?.pitches || [], 'id')
    ), [club])

    const isBusy = useMemo(() => {
        return clubBusy || !club?.id || venuesBusy
    }, [clubBusy, club, venuesBusy])

    // pitches API does not include pitchSortRank values, but we have a sorted pitch list in context
    const pitchesSorted = useMemo(() => {
        // filter a copy of that list per venue
        if (!pitchNameIdList || !pitches) return []
        return pitchNameIdList.map(({ id }) => pitches.find(p => p.id === id)).filter(p => p)
    }, [pitchNameIdList, pitches])

    const venuesPitchesSorted = useMemo(() => {
        if (!venues) return []
        const venuePitches = pitchesSorted ? groupBy(pitchesSorted, 'venue') : {}
        
        // sort venuePitches by lowest pitchsortrank (lookup its index the pitch list array)
        return Object.entries(venuePitches)
            .sort(([aVenue, aPitches], [bVenue, bPitches]) => {
                const aIndices = aPitches.map(p => pitchNameIdList.indexOf(pitchNameIdList.find(listPitch => listPitch.id === p.id)))
                const bIndices = bPitches.map(p => pitchNameIdList.indexOf(pitchNameIdList.find(listPitch => listPitch.id === p.id)))
                return Math.min(...aIndices) - Math.min(...bIndices)
            })
            .map(([v, p]) => {
                const venueData = venues.find(ven => ven['@id'] === v)
                return {
                    venue: venueData,
                    venueName: venueData?.name, // may be undefined
                    pitches: p
                }
            })
    }, [pitchesSorted, venues])

    useEffect(() => {
        let isCancelled = false
        let buffer = []

        async function fetchVenues() {
            if (!pitches?.length) return
            if (!isCancelled) {
                setVenues([])
                setVenuesBusy(true)
            }
            try {
                const venue_list = [...(new Set(pitches.map(p => p.venue)))]
                for (const venue_id of venue_list) {
                    // fetch Data

                    if (!venue_id || (typeof venue_id !== 'string')) continue
                    const { data, error } = await get(venue_id)
                    // push to buffer
                    data && buffer.push(data)
                }
                if (!isCancelled) {
                    setVenues(buffer)
                }
            } catch (e) { console.log(e) } finally {
                if (!isCancelled) {
                    setVenuesBusy(false)
                }
            }
        }

        fetchVenues()

        return () => { isCancelled = true }
    }, [pitches])

    return {
        venuePitches: venuesPitchesSorted,
        clubLogo: logo,
        isBusy,
    }
}