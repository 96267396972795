import React from "react"
import { css } from "@emotion/react"
import { useCallback, useContext, useMemo } from "react"
import ZoneResults from "../test/ZoneResults"
import TestCategoryIcon from "../testCategory/TestCategoryIcon"
import { format } from "date-fns"
import { avgFromTest, rangePercentageFromTest } from "../../utils/analysisCalculations"
import GridModeContext from "../../context/GridMode"
import RenderWhenVisible from "../_general/RenderWhenVisible"
import PitchBg from "../_general/pitch/pitchBg"
import getTestCategoryGroupColor from "../../utils/getTestCategoryGroupColor"
import LocaleContext from "../../context/Locale"
import { FormattedMessage, useIntl } from "react-intl"

export default React.memo(function GridListTest({ pitch, testMoment, styles, ...props }) {

    const { formatMessage } = useIntl()
    const { data, band, timestamp, showDate } = testMoment
    const { datefnsLocale } = useContext(LocaleContext)
    const { setGridMode } = useContext(GridModeContext)
    const [averageMeasurement, rangePercentage] = useMemo(() => {
        return [avgFromTest(data), rangePercentageFromTest(data)]
    }, [data])
    const cycleGridMode = useCallback(() => {
        setGridMode('increment')
    }, [setGridMode])

    const noGrid = useMemo(() => {
        return (data.parameterResults.length > 0) && (!data?.zoneResults.length)
    }, [data])

    const backgroundType = useMemo(() => {
        return pitch?.pitchBackground || pitch?.club?.sport
    }, [pitch])

    const testColor = useMemo(() => {
        return getTestCategoryGroupColor(data.testCategory.group)
    }, [getTestCategoryGroupColor, data])

    return <div
        css={styles.momentGrid(band)}
        {...props}
    >
        <div css={styles.header}>
            <TestCategoryIcon
                iconName={data.testCategory.icon}
                color={testColor}
                css={css`height: 1.5em; width: 1.5em;`}
            />
            <div css={css`display: flex; gap: 1em; flex-grow: 1; justify-content: space-between;`}>
                <h3 css={[styles.title(showDate), css`color:white;`]}>
                    {format(
                        timestamp * 1000,
                        "PP",
                        { locale: datefnsLocale }
                    )}
                </h3>
                <h3 css={styles.title(true)}>
                    {format(
                        timestamp * 1000,
                        'HH:mm',
                        { locale: datefnsLocale }
                    )}
                </h3>
            </div>
        </div>
        <div css={styles.subtitle}>
            {averageMeasurement !== undefined &&
                <div css={css`text-transform: capitalize;`}>
                    {`${formatMessage({ id: 'averageAbbr' })}: ${averageMeasurement}`}
                </div>
            }
            {rangePercentage !== undefined &&
                <div>
                    <FormattedMessage id='dataRange' />
                    {' '}
                    {rangePercentage}
                </div>
            }
        </div>
        <div >
            {noGrid ?
                <h2>
                    <FormattedMessage id='thisTestHasNoGrid' />
                </h2>
                :
                <RenderWhenVisible fallback={<PitchBg type={backgroundType} css={css`display: flex;`} />}>
                    <ZoneResults
                        onClick={cycleGridMode}
                        gridId={data.id}
                        backgroundType={backgroundType}
                        zoneResults={data.zoneResults}
                        isPercentageTemplate={data.testCategory?.pitchTemplate?.templateType === 'percentage'}
                        decimals={data.testCategory?.allowedDecimals}
                        getZoneBackendColor={(zone) => (zone.rating?.assessment?.frontendCode)}
                        css={[styles.clickable, css`font-size: 0.8em;`]}
                        title={formatMessage({ id: 'toggleMode' })}
                    />
                </RenderWhenVisible>
            }
        </div>
    </div>
})