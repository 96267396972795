
import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import NumberScroller from '../_control/NumberScroller'
import { css } from '@emotion/react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { useIntl, FormattedMessage } from 'react-intl'
import capitalize from 'voca/capitalize'
import useBridge from '../../hooks/useBridge'
import Selector from '../_control/OptionsSelector'
import PitchContext from '../../context/Pitch'

export default function TopDressingForm({
    eventType,
    event,
    onPostRequest,
}) {
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()

    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [amount, setAmount] = useState(0)
    const [material, setMaterial] = useState()

    const { data: topDressingMaterialsOptions, loading: loadingMaterials } = useBridge('/api/current/frontend-bridge/top-dressing-materials')
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/top-dressing-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setAmount(data.amount)
                setMaterial(data.topDressingMaterial)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        //ensure required fields
        if (!material) return setFormError(formatMessage({ id: 'pleaseSelectMaterial' }))

        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            amount,
            topDressingMaterial: material,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `TopDressing event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [amount, pitch, selectedDateTime, timeTaken, material])

    const isBusy = useMemo(() => {
        return isLoading || loadingMaterials
    }, [isLoading, loadingMaterials])

    return <>
        {isBusy ? <FormLoader /> :
            (<div css={css`display: flex; flex-direction: column; width: 100%; align-items: flex-start;`}>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <div css={css`display: flex; gap: 1em 4em; flex-wrap: wrap; margin: 1em 0;`}>
                    <NumberScroller
                        label={formatMessage({ id: 'timeTaken' })}
                        minVal={0}
                        maxVal={24}
                        state={timeTaken}
                        unit={capitalize(formatMessage({ id: 'hours' }))}
                        step={0.5}
                        setState={setTimeTaken}
                    />
                    <NumberScroller
                        label={formatMessage({ id: 'amount' })}
                        minVal={0}
                        maxVal={999999999}
                        inputWidthEm={8}
                        unit={capitalize(formatMessage({ id: 'kilogram' }))}
                        setState={setAmount}
                        state={amount}
                    />
                </div>
                <Selector
                    label={capitalize(formatMessage({ id: 'material' }))}
                    optionsObject={topDressingMaterialsOptions}
                    setState={setMaterial}
                    state={material}
                />
            </div>)
        }
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            type="button"
            onClick={(e) => validateAndSubmit(e)}
            disabled={isBusy}
        >
            <FormattedMessage id='save' />
        </button>
    </>
}
