import { css } from "@emotion/react"
import { colors, fonts, timings } from '../../../style/vars'
import PageTitle from "../../_general/PageTitle"
import Table from "../../_general/Table"
import useRawApiState from "../../../hooks/useRawApiState"
import { useCallback, useContext, useMemo, useState } from "react"
import Loader from "../../_general/Loader"
import Modal from "../../_layout/Modal"
import trashIcon from '@assets/icons/delete.svg'
import { ReactSVG } from "react-svg"
import SensorForm from "./SensorForm"
import DeleteWarnModal from "../../_control/DeleteWarnModal"
import useLdjsonApi from "../../../hooks/useLdjsonApi"
import SensorLocationPicker from "./SensorLocationPicker"
import BatteryIndicator from "./BatteryIndicator"
import ViewPortContext from "../../../context/ViewPort"
import { groupBy } from "lodash"

export default function SettingsCovermaster({ ...props }) {
    const { del } = useLdjsonApi()

    const [refetchDependency, setRefetchDependency] = useState({})

    // fetch all sensors that the user has access to
    const { data: devices, isBusy } = useRawApiState('/api/current/devices', {}, [refetchDependency])

    const loading = useMemo(() => {
        return isBusy || !devices
    }, [isBusy, devices])

    const [selectedDevice, setSelectedDevice] = useState()
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const sortedDevices = useMemo(() => {
        if (!devices) return []
        // filter on all non-soft deleted covermaster devices
        const filtered = devices
            .filter(device => !device.deleted /* && (device.deviceType === 'cover-master') */)  /* TO DO: PUT THIS BACK LATER */
            //sort on pitch, and then name
            .toSorted((a, b) => {
                const pitchNameA = a.latestDevicePlacementEvent?.pitch?.name
                const pitchNameB = b.latestDevicePlacementEvent?.pitch?.name

                if (!pitchNameA) return 1

                return pitchNameA === pitchNameB ?
                    b.name.localeCompare(a.name)
                    : pitchNameB?.localeCompare(pitchNameA)
            }, {})

        return filtered
    }, [devices])

    const pitchGroupedDevices = useMemo(() => {
        // first, filter out all the nulled pitches
        const filtered = sortedDevices.filter(device => !!device.latestDevicePlacementEvent?.pitch)
        // group by pitch id
        const pitchGrouped = groupBy(filtered, (device) => device.latestDevicePlacementEvent.pitch.id)
        return pitchGrouped

    }, [sortedDevices])
    
    const showAddFirstMessage = useMemo(() => {
        if (!devices) return false
        return sortedDevices.length === 0
    }, [sortedDevices, devices])

    const handleEditDevice = useCallback((row) => {
        if (loading) return
        setSelectedDevice(row)
        setShowEditModal(true)
    }, [selectedDevice, showEditModal, loading])

    const handleSetDeleteModal = useCallback((row) => {
        if (loading) return
        setSelectedDevice(row)
        setShowDeleteModal(true)
    }, [selectedDevice, showDeleteModal, loading])

    const clearSelection = useCallback(() => {
        setSelectedDevice()
        setShowDeleteModal(false)
        setShowEditModal(false)
    }, [])

    const handleDeleteDevice = useCallback(async () => {
        if (loading) return
        if (!selectedDevice || !Number.isFinite(selectedDevice.id)) return
        //actually delete
        try {
            await del(`/api/current/devices/${selectedDevice.id}`)
        } catch (e) {
            console.error(e)
        } finally {
            setRefetchDependency({})
            clearSelection()
        }
    }, [selectedDevice, del, clearSelection, loading])

    const { showCompactUI } = useContext(ViewPortContext)

    return (<div {...props}>
        <PageTitle>
            <span css={{ color: colors.main1 }}>
                Integrations / Covermaster {/* TO DO translate */}
            </span>
        </PageTitle>

        <div css={css`width: 100%;`}>
            <div css={style.title}>
                <div css={css`display: flex; gap: 1em;`}>
                    Manage Sensors {/* TO DO translate */}
                    {loading && <Loader size={'1em'} />}
                </div>
            </div>

            {
                <>
                    <div css={css`
                        margin-top: 2em;
                        opacity: ${loading ? 0.4 : 1};
                        pointer-events: ${loading ? 'none' : 'unset'}; 
                        transition: opacity ${timings.smooth};
                    `}>
                        <Table
                            showHeader={true}
                            data={sortedDevices}
                            rowKey={r => r.id}
                            onRowClick={(row) => handleEditDevice(row)}
                            columns={[
                                {
                                    key: 'pitch',
                                    header: 'Pitch',/* TO DO translate */
                                    sortable: false,
                                    wide: false,
                                    renderData: row => <span css={css`font-size: 1.5em; white-space: nowrap;`}>
                                        {row.latestDevicePlacementEvent?.pitch?.name}
                                    </span>,
                                },
                                {
                                    key: 'name',
                                    header: 'Name',/* TO DO translate */
                                    sortable: false,
                                    wide: false,
                                    renderData: row => <span css={css`font-size: 1.5em;  white-space: nowrap;`}>
                                        {row.name}
                                    </span>,
                                },
                                {
                                    key: 'position',
                                    header: 'Position',/* TO DO translate */
                                    sortable: false,
                                    wide: false,
                                    renderData: row => {
                                        // create a list of other sensors on this pitch
                                        const pitchId = row.latestDevicePlacementEvent?.pitch?.id
                                        const otherDevices = pitchGroupedDevices[pitchId]?.filter(device => device.id !== row.id) ?? []

                                        return <span css={css`
                                            font-size: 1.5em;
                                            width: 4em;
                                        `}>
                                            <SensorLocationPicker
                                                posX={row.latestDevicePlacementEvent?.posX}
                                                posY={row.latestDevicePlacementEvent?.posY}
                                                isUnderCover={row.latestDevicePlacementEvent?.deviceMode === 'under-cover'}
                                                disabled={true}
                                                otherDevices={otherDevices}
                                            />
                                        </span>
                                    },
                                },
                                {
                                    key: 'battery',
                                    header: 'Battery',/* TO DO translate */
                                    sortable: false,
                                    wide: false,
                                    renderData: row => <span css={css`
                                        width: 100%;
                                        display: grid;
                                        place-items: center;
                                    `}>
                                        <BatteryIndicator percentage={row.latestDeviceStatusEvent?.batteryLevel ?? 0} />
                                    </span>,
                                },
                                {
                                    key: 'sensorId',
                                    header: 'Sensor Id',/* TO DO translate */
                                    sortable: false,
                                    wide: true,
                                    renderData: row => <span css={css`font-size: 1.5em;`}>
                                        {row.devEUI}
                                    </span>,
                                },
                                {
                                    key: 'deleteSensor',
                                    header: '',
                                    sortable: false,
                                    wide: false,
                                    renderData: row => <span css={css`font-size: 1.5em;`}>
                                        <ReactSVG
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleSetDeleteModal(row)
                                            }}
                                            src={trashIcon}
                                            css={css`
                                                width: 1.2em; 
                                                height: 1.2em;

                                                &:hover {
                                                    color: ${colors.warn};
                                                }
                                            `}
                                        />
                                    </span>,
                                },
                            ].filter(col => showCompactUI ? ['name', 'position', 'deleteSensor'].includes(col.key) : true)}
                        />

                        {/* Add new sensor button/row */}
                        <Table
                            showHeader={false}
                            data={[{ id: 1 }]}
                            rowKey={r => r.id}
                            onRowClick={() => handleEditDevice()}
                            columns={[
                                {
                                    key: 'createNew',
                                    sortable: false,
                                    wide: true,
                                    renderData: row => <div css={css`
                                        font-size: 1.5em; 
                                        width: 100%;
                                        display: grid; 
                                        place-items: center;
                                        color: ${colors.main1};
                                    `}>
                                        {showAddFirstMessage ? `click here to add your first sensor` : '+'}{/* to do: translate this */}
                                    </div>,
                                },
                            ]}
                        />
                    </div>
                </>
            }
        </div>

        {showEditModal && (!showDeleteModal) &&
            <Modal
                header={!selectedDevice ? 'ADD NEW SENSOR' : `EDIT ${selectedDevice.name}`} /* TO DO translate */
                onClickClose={() => {
                    setShowEditModal(false)
                    setSelectedDevice()
                }} >
                <SensorForm
                    selectedDevice={selectedDevice}
                    pitchGroupedDevices={pitchGroupedDevices}
                    afterRequest={() => {
                        setShowEditModal(false)
                        setSelectedDevice()
                        setRefetchDependency({})
                    }}
                />
            </Modal>
        }

        {showDeleteModal && (!showEditModal) &&
            <DeleteWarnModal
                header={`delete ${selectedDevice.name}`} /* TO DO translate  */
                message={`are you sure you want to delete ${selectedDevice.name}?`}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setSelectedDevice()
                }}
                handleDelete={handleDeleteDevice}
            />
        }

    </div>)
}

const style = {
    title: css`
        font-family: ${fonts.special};
        font-size: 1.5em;
        align-self: center;
        color: ${colors.soft};
    `,
}