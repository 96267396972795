import { css } from "@emotion/react"
import { useCallback, useMemo } from "react"
import { colors } from "../../../style/vars"
import sensorIcon from '@assets/icons/sensor.svg'
import { ReactSVG } from "react-svg"
import { darken } from "polished"
import PitchBg from "../../_general/pitch/pitchBg"
import BatteryIndicator from "./BatteryIndicator"

// according to specs this is going to be a static 3x3 grid, forever and ever

export default function SensorLocationPicker({
    posX,
    posY,
    handleSetCoordinates,
    isUnderCover = false,
    disabled,
    otherDevices = [],
    pickAnyPosition = false,
    showGrid = true,
    showPitchBg = false,
    pitchBg,
    showBattery = false,
    showPlacementLabel = false,
    ...props }) {

    // TO DO: if pitch context is given, fetches and shows all sensors on this pitch

    // component is a picker or just a display depending on the clickable prop

    // this component will need to know which locations on the pitch (if any) are already occupied

    // we'll keep the same X and Y axis orientation as for the analyses, to keep the weirdness consistent:
    // |x3y1        x3y3
    // |
    // |x1y1        x1y3
    // -------------------

    const readOnly = useMemo(() => {
        return !handleSetCoordinates || disabled
    }, [handleSetCoordinates, disabled])

    const handleCellClick = useCallback((x, y) => {
        if (readOnly) return
        if (posX === x && posY === y) {
            handleSetCoordinates(null, null)
        } else {
            handleSetCoordinates(x, y)
        }
    }, [handleSetCoordinates, readOnly, posX, posY,])

    return <div css={css`
            width: 100%;
            aspect-ratio: 4.5 / 3;

            display: grid;
            grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
            gap: ${showGrid ? 2 : 0}px;
            border: 2px solid transparent;
            background: ${showGrid ? colors.soft : 'transparent'};
            border-radius: 3px;
            position: relative;
        `}
        {...props}
    >
        {[
            [3, 1], [3, 2], [3, 3],
            [2, 1], [2, 2], [2, 3],
            [1, 1], [1, 2], [1, 3]
        ].map(([x, y]) => {

            const isSelected = x === posX && y === posY
            // if we're not editing a specific sensor, but viewing all sensors of the pitch, all sensors are 'otherDevices'
            const occupiedDevice = otherDevices.find(device => device.latestDevicePlacementEvent?.posX === x && device.latestDevicePlacementEvent?.posY === y)
            const occupiedMode = occupiedDevice?.latestDevicePlacementEvent.deviceMode
            const isOccupied = !!occupiedDevice && (occupiedMode !== 'unassigned')

            const mode = (isSelected && isOccupied && !pickAnyPosition) ? 'error'
                : (isSelected && isOccupied && pickAnyPosition) ? 'selected-occupied'
                    : (isSelected && !pickAnyPosition) ? 'selected'
                        : isSelected ? 'selected-empty'
                            : (isOccupied) ? 'occupied'
                                : 'empty'

            return <Cell
                key={`${x}${y}SensorPickerCell`}
                setCoordinates={() => { if (!isOccupied || pickAnyPosition) { handleCellClick(x, y) } }}
                disabled={(readOnly || isOccupied) && !pickAnyPosition}
                mode={mode}
                isUnderCover={!isOccupied ? isUnderCover : (occupiedMode === 'under-cover')}
                showGrid={showGrid}
                battery={occupiedDevice?.latestDeviceStatusEvent?.batteryLevel}
                showBattery={showBattery}
                showPlacementLabel={showPlacementLabel}
                deviceName={occupiedDevice?.name}
            />
        })}

        <div css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
        `}>
            {showPitchBg &&
                <PitchBg type={pitchBg} />
            }
        </div>
    </div>
}
/* TO DO: TEST SENSOR PICKER IN FORM AGAIN */
const selectedColor = darken(0.15)(colors.main1)

function Cell({ setCoordinates, mode, disabled, isUnderCover, showGrid, battery, showBattery, showPlacementLabel, deviceName, ...props }) {

    const empty = mode === 'empty' || mode === 'selected-empty'
    const selected = mode === 'selected' || mode === 'selected-occupied' || mode === 'selected-empty'

    const style = useMemo(() => {

        return {
            cell: css` 
                background: ${selected ? selectedColor : (showGrid ? colors.eventLight : 'transparent')};
                height: 100%;
                width: 100%;
                overflow: hidden;
                z-index: 1;
                position: relative;
            ${!disabled ?
                    `cursor: pointer;
                    &:hover {
                        opacity: ${mode === 'selected' ? 0.75 : 0.4};
                        background: ${selectedColor};
                    }`
                    :
                    ''}`
            ,
            //icon
            icon: css`
                width: 100%;
                height: 100%;
                color: ${mode === 'error' ? 'red' :
                    (isUnderCover ? 'black' : 'white')
                };
                display: grid;
                place-items: center;

            
            > * {
                width: 50%;
                display: grid; 
                place-items: center;
                    }
                    
            `,
            //text
            text: css`
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -2px);
                color: ${(isUnderCover ? 'black' : 'white')};
            `,
            batteryWrapper: css`
                width: 100%;
                height: 100%;
                display: grid;
                place-items: center;
                font-size: 1.3em;
            `,

        }

    }, [selected, showGrid, disabled, mode, isUnderCover])

    return <div
        title={deviceName}
        onClick={setCoordinates}
        css={style.cell} >

        {showBattery && (!empty)
            ?
            <div css={style.batteryWrapper}>
                <BatteryIndicator
                    percentage={battery}
                    backgroundColor={colors.eventDark}
                />
            </div>
            :
            (mode !== 'empty' && mode !== 'selected-empty') && <>
                <ReactSVG
                    src={sensorIcon}
                    css={style.icon}
                />
                {showPlacementLabel &&
                    <div css={style.text}>
                        {isUnderCover ? 'Under' : 'Over'} {/* /* TO DO: TRANSLATE */}
                    </div>
                }
            </>
        }

    </ div>
}