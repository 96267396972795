import React, { createContext } from 'react'
import PropTypes, { number, string } from 'prop-types'
import useRaw from '../hooks/useRawV2'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const ClubContext = createContext()
export default ClubContext

export function RouteBasedClubProvider({ children }) {

    const { clubId } = useParams()

    return (
        <ClubProvider clubId={clubId}>
            {children}
        </ClubProvider>
    )
}

export function QueryBasedClubProvider({ children }) {
    const [searchParams] = useSearchParams()

    return (
        <ClubProvider clubId={searchParams.get('club')} children={children} />
    )
}


export function ClubProvider({
    clubId,
    ...props
}) {
    const clubResult = useRaw(`/api/current/clubs/${clubId}`, [clubId])

    return (
        <ClubContext.Provider
            value={clubResult}
            {...props}
        />
    )
}

ClubProvider.propTypes = {
    clubId: PropTypes.oneOfType([number,string]),
}
