import React, { useCallback, useContext, useMemo } from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import Pitch from '../../context/Pitch'
import Analysis from '../../context/Analysis'
import Test from '../../prop-types/Test'
import { colors, fonts, timings, backendColors } from '../../style/vars'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import ScoreIndicator from '../_general/ScoreIndicator'
import ZoneResults from './ZoneResults'
import Parameter from './Parameter'
import HealthEffectsMap from '../physicalAssessment/HealthEffectsMap'
import InstructionSteps from './InstructionSteps'
import EffectsList from '../physicalAssessment/EffectsList'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import { BodyPartProvider } from '../../context/BodyPart'
import { avgFromTest, rangePercentageFromTest } from '../../utils/analysisCalculations'
import GridModeSwitcher from './GridModeSwitcher'
import { useIntl, FormattedMessage } from 'react-intl'
import AnalysisDate from '../analysis/AnalysisDate'
import { SurfaceHighlightsProvider } from '../../context/SurfaceHighlights'
import PitchEffects from '../physicalAssessment/PitchEffects'
import { SurfaceLayerProvider } from '../../context/SurfaceLayer'
import PreferredViewContext from '../../context/PreferredView'
import SwitcherSimple from '../_control/SwitcherSimple'

export default function TestResultCard({
    test,
    showDetails = false,
    showAnalysisDate = false,
    noNavigate = false,
    ...props
}) {
    const [pitch] = useContext(Pitch)
    const [analysis] = useContext(Analysis)
    const { preferredView, setPreferredView, PREFERRED_VIEW_TYPES } = useContext(PreferredViewContext)

    // aggregate data
    const [averageMeasurement, rangePercentage] = useMemo(() => {
        return [avgFromTest(test), rangePercentageFromTest(test)]
    }, [test])

    const color = getTestCategoryGroupColor(test.testCategory.group)
    const hasAssessment = !!(test && test.score && test.score.assessment)
    const { testCategory } = test
    const { testType } = testCategory || {}
    const hasZoneResults = test.zoneResults.length > 0
    const hasAnyParameterMeasurements = !!test.parameterResults.filter(x => x.measurement !== null).length

    const [showHealthEffects, showPitchEffects, showToggle] = useMemo(() => {
        const hasHealthEffects = !!test?.testCategory?.hasPhysicalAssessments
        const hasPitchEffects = !!test?.testCategory?.hasPitchAssessments
        if (hasHealthEffects && hasPitchEffects) {
            // if test has configuration for both, show a toggle and only one at a time
            switch (preferredView) {
                case PREFERRED_VIEW_TYPES.performance: return [hasHealthEffects, false, true]
                case PREFERRED_VIEW_TYPES.greenkeeping: return [false, hasPitchEffects, true]
                default: return [hasHealthEffects, false, true]
            }
        } else {
            // else show whichever has configuration, if any, and no toggle
            return [hasHealthEffects, hasPitchEffects, false]
        }
    }, [test, preferredView, PREFERRED_VIEW_TYPES])

    const handlePreferredViewChange = useCallback(() => {
        setPreferredView(curr => curr === PREFERRED_VIEW_TYPES.performance ?
            PREFERRED_VIEW_TYPES.greenkeeping
            : PREFERRED_VIEW_TYPES.performance
        )
    }, [setPreferredView, PREFERRED_VIEW_TYPES])

    const { formatMessage } = useIntl()

    return (
        <NavLink
            to={(pitch && analysis && test && !noNavigate) ? `/pitch/${pitch.id}/analysis/${analysis.id}/test/${test.id}` : {}}
            css={style.card(color, noNavigate)} {...props}
            style={{ textDecoration: 'none' }} /* Necessary for pdf-gen */
        >
            {/* Header */}
            <div css={css`display:flex; align-items: center; gap: 0.75rem;`} >
                {<div css={[style.iconContainer, css`
                    @media screen and (max-width: 600px) {
                        display: ${showDetails ? 'flex' : 'none'};
                    }
                `]}>
                    <TestCategoryIcon
                        css={style.icon}
                        iconName={test.testCategory.icon}
                        color={color}
                    />
                </div>}
                <div css={style.headerText} >
                    <h4 css={style.title}>
                        {test.testCategory.name}
                    </h4>
                    {(test.analysisDate && showAnalysisDate) &&
                        <h5 css={[style.title, css`opacity: 0.5; margin-left: 0.75rem;`]}>
                            <AnalysisDate
                                date={test.analysisDate}
                                timeZone={pitch?.venue?.timeZone}
                                css={css`font-size: 1em;`}
                            />
                        </h5>
                    }
                </div>
                {(hasAssessment) &&
                    <ScoreIndicator
                        value={test.score.score}
                        color={backendColors[test.score.assessment.frontendCode]}
                    />
                }
            </div>
            {/* Main content */}
            <BodyPartProvider><SurfaceLayerProvider>
                <div css={style.mainContent}>
                    <div css={style.visualsLayout(showDetails)}>
                        {showToggle && showDetails &&
                            <SwitcherSimple
                                checked={showPitchEffects}
                                onClick={handlePreferredViewChange}
                                checkedLabel={formatMessage({ id: 'grounds' })}
                                unCheckedLabel={formatMessage({ id: 'performance' })}
                                buttonCenter={true}
                            />
                        }
                        {(showHealthEffects && showDetails) &&
                            <div style={{ height: '20em'}}>
                                <HealthEffectsMap
                                    css={style.polyModel}
                                    physicalAssessments={test.physicalImpact || []}
                                />
                            </div>
                        }
                        {(showPitchEffects && showDetails) &&
                            <div style={{ height: '20em', alignSelf: 'center' }}>
                                <SurfaceHighlightsProvider pitchImpacts={test.pitchImpact || []}>
                                    <PitchEffects css={style.polyModel} />
                                </SurfaceHighlightsProvider>
                            </div>
                        }
                        {(testType === 'field' && hasZoneResults) &&
                            <ZoneResults
                                gridId={test.id}
                                backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                                css={showDetails && style.field}
                                zoneResults={test.zoneResults}
                                isPercentageTemplate={test.testCategory?.pitchTemplate?.templateType === 'percentage'}
                                decimals={test.testCategory?.allowedDecimals}
                                getZoneBackendColor={(zone) => (zone.rating?.assessment?.frontendCode)}
                            />
                        }
                        {testType === 'laboratory' &&
                            (hasAnyParameterMeasurements ?
                                <div css={style.section}>
                                    {test.parameterResults.map(parameterResult => (
                                        parameterResult.measurement !== null && (
                                            <Parameter
                                                style={{ fontSize: '0.875em' }}
                                                key={parameterResult['@id']}
                                                parameterResult={parameterResult}
                                                showOptimum
                                            />
                                        )
                                    ))}
                                </div>
                                :
                                <div css={style.section}>
                                    <InstructionSteps
                                        title={formatMessage({ id: 'testEmptyEnterResults' })}
                                    />
                                </div>
                            )
                        }
                        <div css={style.aggregates}>
                            {!hasAnyParameterMeasurements && <GridModeSwitcher />}
                            {rangePercentage !== undefined &&
                                <div css={css`font-size: 0.9375em; text-transform: capitalize;`}>
                                    <FormattedMessage id='dataRange' />
                                    {': ' + rangePercentage}
                                </div>
                            }
                            {averageMeasurement !== undefined &&
                                <div css={css`font-size: 0.9375em;`}>
                                    <FormattedMessage id='averageValue' />
                                    {': ' + averageMeasurement}
                                </div>
                            }
                        </div>
                    </div>
                    {showDetails &&
                        <div css={style.rightHalf}>
                            {showHealthEffects && <>
                                <h3 css={css`font-size: 1.75em; font-weight: 200; line-height: 1; margin-bottom: 0.875em; font-family: ${fonts.special}; color: ${colors.subtle};`}>
                                    <FormattedMessage id='performanceAndWelfareInfluencers' />
                                </h3>
                                <EffectsList
                                    effects={test.physicalImpact || []}
                                    css={css`margin-bottom: 3em;`}
                                    type='player'
                                />
                            </>}
                            {showPitchEffects && <>
                                <h3 css={css`font-size: 1.75em; font-weight: 200; line-height: 1; margin-bottom: 0.875em; font-family: ${fonts.special}; color: ${colors.subtle};`}>
                                    <FormattedMessage id='surfaceInfluencers' />
                                </h3>
                                <EffectsList
                                    effects={test.pitchImpact || []}
                                    css={css`margin-bottom: 3em;`}
                                    type='pitch'
                                />
                            </>}
                            {!!(test.advices && test.advices.length) && <>
                                <h3 css={css`font-size: 1.75em; font-weight: 200; line-height: 1; margin-bottom: 0.875em; font-family: ${fonts.special}; color: ${colors.subtle};`}>
                                    <FormattedMessage id='advice' />
                                </h3>
                                {test.advices.map(({ id, instruction }) => (
                                    <div key={id} dangerouslySetInnerHTML={{ __html: instruction }} />
                                ))}
                            </>}
                        </div>
                    }
                </div>
            </SurfaceLayerProvider></BodyPartProvider>
        </NavLink>
    )
}

TestResultCard.propTypes = {
    test: Test(),
    showAnalysisDate: PropTypes.bool,
    showDetails: PropTypes.bool,
    showDetails: PropTypes.bool,
    noNavigate: PropTypes.bool,
}

const style = {
    card: (color, noNavigate) => css`
        cursor: ${noNavigate ? 'unset' : ''};
        text-decoration: none;
        display: block;
        border-radius: 0.3125em;
        overflow: hidden;
        padding: 1.25em;
        color: ${colors.white};
        position: relative;
        z-index: 1;
        transition: all ${timings.responsive} ease;
        box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: ${colors.stuff};
            z-index: -2;
            transition: all ${timings.responsive} ease;
            opacity: 0.66;
        }
        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(170deg, rgba(255,255,255,0) 50%, ${color});
            z-index: -1;
            transition: all ${timings.responsive} ease;
            opacity: 0.15;
        }
        ${!noNavigate ?
            `
            &:hover {
                transition: all ${timings.snappy} ease;
                box-shadow: 0 0.75em 1.5em 0 ${rgba(colors.black, 0.5)};
                &::before {
                    transition: all ${timings.snappy} ease;
                    opacity: 1;
                    background-color: ${colors.solid};
                }
                &::after {
                    transition: all ${timings.snappy} ease;
                    opacity: 0.25;
                }
            }
            ` : ''
        }
        
    `,
    iconContainer: css`
        flex-shrink: 0;
        width: 3em;
        height: 3em;
        border-radius: 1.5em;
        overflow: hidden;
        background-color: ${rgba(colors.subtle, 0.2)};
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    icon: css`
        width: 100%;
        height: 100%;
        max-height: 65%;
        max-width: 65%;
    `,
    title: css`
        display: inline-block;
        font-size: 1.25em;
        line-height: 1.2;
        font-family: ${fonts.special};
        font-weight: 700;
        text-transform: uppercase;
    `,
    section: css`
        display: grid;
        grid-gap: 0.5em;
        overflow: hidden;
        background-color: ${rgba(colors.light, 0.1)};
        border-radius: 0.3125em;
        padding: 0.5em;
    `,
    field: css`
        overflow: hidden;
        background-color: ${rgba(colors.light, 0.1)};
        border-radius: 0.3125em;
        padding: 0.5em;
        min-height:20em;
    `,
    aggregates: css`
        display: flex;
        justify-content: space-between;
        margin-top: 0.2em;
        align-items: center;
        flex-direction: row-reverse;
    `,
    headerText: css`
        flex-grow: 1; 
        flex-shrink: 1; 
        flex-direction: column; 
        align-items: stretch;
    `,
    mainContent: css`
        display: flex; 
        margin-top: 1em; 
        gap: 2em;
        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    `,
    rightHalf: css`
        flex-grow: 1; 
        line-height: 1.375; 
        padding-left: 1.5em; 
        border-left: 1px solid rgba(255,255,255,0.1);
        @media screen and (max-width: 600px) {
            padding-left: 0;
            border-left: none;
        } 
    `,
    visualsLayout: (showDetails) => css`
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 1.2em;
        width: ${showDetails ? 'min(45%, 45em)' : '100%'};
        max-width: ${showDetails ? '420px' : 'none'};
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    `,
    polyModel: css`
        height: 20em;
    `
}