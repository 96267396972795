import useRawV2 from '../../hooks/useRawV2'
import SettingsSelect from '../settings/SettingsSelect'
import { useState, useMemo, useContext, useEffect } from 'react'
import Loader from '../_general/Loader'
import ProfileContext from '../../context/Profile'

export default function AdminAnalyticsClubSelector({ ...props }) {

    const [profile] = useContext(ProfileContext)

    const [unpaginatedClubs] = useRawV2(profile?.admin ? `/api/current/clubs?pagination=false` : undefined)
    const [selectedClub, setSelectedClub] = useState(localStorage.getItem('adminAnalyticsClub') ? Number(localStorage.getItem('adminAnalyticsClub')) : undefined)
    const clubsOptions = useMemo(() => {
        return [
            <option value="">
                None
            </option>,
            unpaginatedClubs?.['hydra:member'].map(c =>
                <option value={c.id}>
                    {c.name}
                </option>
            )] ?? []
    }, [unpaginatedClubs])

    useEffect(() => {
        if (selectedClub === undefined) return
        localStorage.setItem('adminAnalyticsClub', selectedClub)
    }, [selectedClub])

    return unpaginatedClubs ?
        <SettingsSelect
            options={clubsOptions}
            value={selectedClub}
            onChange={(e) => setSelectedClub(e.target.value)}
            {...props}
        />
        :
        <Loader size={'1em'} />
}