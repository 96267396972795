import React, { useState } from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { colors, fonts, timings } from '../../style/vars'
import stadiumBg from '@assets/images/pitch_stadium_bg.svg'
import practiceBg from '@assets/images/pitch_practice_bg.svg'
import stadiumIcon from '@assets/icons/stadium2.svg'
import practiceIcon from '@assets/icons/pitch.svg'
import weatherIcon from '@assets/icons/nav/weather.svg'
import plusIcon from '@assets/icons/nav/new_analysis.svg'
import calendarIcon from '@assets/icons/nav/calendar.svg'
import reportingIcon from '@assets/icons/nav/pitch_reporting.svg'
import historyIcon from '@assets/icons/nav/pitch_history.svg'
import PropTypes from 'prop-types'
import ldJsonPropTypes from '../../prop-types/_ld-json'
import LazyLoader from '../_general/LazyLoader'
import LastAnalysisSection from './LastAnalysisSection'
import TodaysEventsSection from './TodaysEventsSection'
import { useIntl } from 'react-intl'

// render this component when pitch data is already present higher up the component tree to prevent header images changing
// alternatively, render without a pitch prop to use as a placeholder/loader

function PitchCardWithData({ pitch, venue, hideHistory = false, ...props }) {

    const [showPitchInfo, setShowPitchInfo] = useState(false)
    const { formatMessage } = useIntl()

    return (
        <div css={[style.card, !pitch && css`opacity: 0.6; pointer-events: none;`]} {...props}>

            <div css={style.header(pitch?.stadium)}
                onClick={() => setShowPitchInfo(current => !current)}
            >
                <h3 css={style.title}>
                    {pitch?.name || formatMessage({id:'loadingEllipses'})}
                </h3>
                <ReactSVG src={pitch?.stadium ? stadiumIcon : practiceIcon} css={style.headerPitchIcon} />
                {showPitchInfo && <div css={style.pitchInfo}>
                    <span>{`${formatMessage({id:'type'})}: ` + (pitch?.pitchType?.name || formatMessage({id:'notApplicableAbbr'}))}</span>
                    <span>{`${formatMessage({id:'grassType'})}: ` + (pitch?.grassType || formatMessage({id:'notApplicableAbbr'}))}</span>
                </div>}
            </div>

            <div css={style.body}>
                <LazyLoader>
                    <LastAnalysisSection pitch={pitch} />
                </LazyLoader>
                <hr css={style.hr} />
                <LazyLoader>
                    <TodaysEventsSection pitch={pitch} venue={venue} />
                </LazyLoader>
            </div>

            <div css={[style.navButtons, !pitch && css`pointer-events: none; opacity: 0.2;`]}>
                {!hideHistory &&
                    <NavLink to={pitch && `/pitch/${pitch.id}`} title={formatMessage({id:'historyOfPitchData'}).toLowerCase()}>
                        <ReactSVG src={historyIcon} />
                    </NavLink>
                }
                <NavLink to={pitch && `/events?pitch=${pitch.id}`} title={formatMessage({id:'calendar'}).toLowerCase()}>
                    <ReactSVG src={calendarIcon} />
                </NavLink>
                {pitch?.weatherFlowStations?.[0] &&
                    <NavLink to={pitch && `/weather?pitch=${pitch.id}`} title={formatMessage({id:'weather'}).toLowerCase()}>
                        <ReactSVG src={weatherIcon} />
                    </NavLink>
                }
                <NavLink to={pitch && `/reporting?pitch=${pitch.id}`} title={formatMessage({id:'pitchReporting'}).toLowerCase()}>
                    <ReactSVG src={reportingIcon} />
                </NavLink>
                <NavLink to={pitch && `/create-analysis/${pitch.id}`} title={formatMessage({id:'createNewAnalysis'}).toLowerCase()}>
                    <ReactSVG src={plusIcon} />
                </NavLink>
            </div>

        </div>
    )
}
PitchCardWithData.propTypes = {
    pitch: PropTypes.shape({
        ...ldJsonPropTypes,
        grassType: PropTypes.string,
        id: PropTypes.number,
        latestAnalysis: PropTypes.shape({
            ...ldJsonPropTypes
        }),
        name: PropTypes.string,
        orientation: PropTypes.oneOf(['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']),
        stadium: PropTypes.bool,
        type: PropTypes.object,
    }),
    hideHistory: PropTypes.bool,
}

export default PitchCardWithData

const style = {
    card: css`
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: ${colors.solid};
        grid-template-rows: 5em 1fr 4em;
        grid-template-areas: 
            "header"
            "body"
            "links";

        box-shadow: 0 0 4px black;
    `,
    header: (isStadium) => css`
        display: grid;
        grid-area: header;
        background: no-repeat center url(${isStadium ? stadiumBg : practiceBg});
        background-color: black;
        background-size: cover;
        grid-template-columns: 1fr 5em;
        position: relative;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
            outline: 2px solid ${colors.light};
        }
    `,
    title: css`
        align-self: center;
        margin-left: 1em;
        font-size: 1.4em;
        font-weight: 500;
        font-family: ${fonts.main};
        overflow-wrap: break-word;
        overflow: hidden;
    `,
    headerPitchIcon: css`
        align-self: center;
        margin-left: 0.7em;
        height: 3em;
        width: 3em;
        color: ${colors.main1};
    `,
    pitchInfo: css`
        position: absolute;
        grid-template-rows: 1fr 1fr;
        display: grid;
        overflow: hidden;
        opacity: 0;
        top: 50%;
        background: ${colors.dark};
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.8em;
        padding: 1em;
        color: ${colors.light};
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        grid-gap: 0.2em;
        z-index: 1;
        animation-name: slide-down;
        animation-duration: ${timings.smooth};
        animation-fill-mode: forwards;

        @keyframes slide-down {
            0% {top: 50%; opacity: 0;}
            100% {top: 100%; opacity: 0.8;}
        }
    `,
    body: css`
        grid-area: body;
        min-height: 19em; 
        padding:  1.6em;
        font-size: 0.9em;
        display: grid;
        grid-auto-rows: min-content;
        grid-gap: 1em;

        > a {
            transition: background ${timings.smooth};
            border-radius: 5px;
            &:hover {
                background: ${colors.stuff};
            }
        }
    `,
    navButtons: css`
        grid-area: links;
        display: flex;

        > * {  // navlink
            flex-grow: 1;
            display: grid; 
            place-items: center;
            cursor: pointer;
            transition: background ${timings.smooth};

            &:hover {
                > * { // svg wrapper
                    color: ${colors.white};
                    fill: ${colors.white};
                }
                background: ${colors.stuff};
            }

            > * { // svg wrapper
                transition: color ${timings.smooth}, fill ${timings.smooth};
                color: ${colors.light};
                fill: ${colors.light};
                width: 1.6em;
                height: 1.6em;
            }

        }
    `,
    hr: css`
        width: 100%; 
        height: 1px; 
        opacity: 0.2; 
        color: ${colors.light};
    `
}