import { css } from "@emotion/react"
import { colors } from "../../../style/vars"
import { useMemo } from "react"


function getPercentageColor(percentage) {
    if (percentage <= 35) return colors.warn
    return colors.main1
}

export default function BatteryIndicator({ percentage, backgroundColor = 'transparent', ...props }) {

    const noData = useMemo(() => {
        return !Number.isFinite(percentage)
    }, [percentage])

    return <div css={css`
        display: flex;
        flex-direction: column;
        align-items:center;
        opacity: ${noData ? 0.5 : 1};
    `} {...props}>
        <div css={css`
            width: 0.5em;
            height: 0.25em;
            background: ${colors.soft};
        `} />

        <div css={css`
            width: 1.2em;
            height: 2em;
            border: 0.2em solid ${colors.soft};
            border-radius: 1px;
            display: flex;
            flex-direction: column-reverse;
            background: ${backgroundColor};
        `}>
            <div css={css`
                width: 100%;
                height: ${percentage ?? 0}%;
                background: ${getPercentageColor(percentage)};
            `} />

        </div>

        <span css={css`margin-top: 3px; font-size: 0.8em;`}>
            {Number.isFinite(percentage) &&
                `${percentage}%`
            }
        </span>
    </div>
}