import { useContext, useMemo, useState } from "react"
import PageSubtitle from "../../_general/PageSubtitle"
import SensorLocationPicker from "./SensorLocationPicker"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import { css } from "@emotion/react"
import SwitcherSimple from "../../_control/SwitcherSimple"
import Orientation from "../../test/Orientation"
import { colors } from "../../../style/vars"
import { formatDistanceToNow, fromUnixTime } from "date-fns"
import LocaleContext from "../../../context/Locale"
import useRawApiState from "../../../hooks/useRawApiState"
import Loader from "../../_general/Loader"

export default function PitchSensorsSection({ ...props }) {
    const { datefnsLocale } = useContext(LocaleContext)
    const { devices } = useContext(CovermasterContext)
    const {
        titleMessage,
        selectedPosition,
        handleSelectPosition,
        isCoverOn,
        coverCss,
        pitch,
        pitchBg,
        positionNames,
    } = useContext(PitchTelemetry)

    const [showBatteryOnGrid, setShowBatteryOnGrid] = useState(true)

    const pitchDevices = useMemo(() => {
        return devices?.filter(d => d.latestDevicePlacementEvent?.pitch?.id === pitch.id)
    }, [devices, pitch])

    const selectedDeviceInfo = useMemo(() => {
        const selected = pitchDevices?.find(d => d.latestDevicePlacementEvent?.posX === selectedPosition?.posX && d.latestDevicePlacementEvent?.posY === selectedPosition?.posY)

        const x = selected?.latestDevicePlacementEvent?.posX
        const y = selected?.latestDevicePlacementEvent?.posY

        if (!x || !y) return

        const timestamp = selected.latestDeviceStatusEvent?.timestamp

        const formattedStatusTime = timestamp ? formatDistanceToNow(
            fromUnixTime(selected.latestDeviceStatusEvent?.timestamp),
            { addSuffix: true, locale: datefnsLocale }
        ) : 'never' /* TO DO: translate */

        const formattedPlacementTime = timestamp ? formatDistanceToNow(
            fromUnixTime(selected.latestDevicePlacementEvent?.timestamp),
            { addSuffix: true, locale: datefnsLocale }
        ) : 'never' /* TO DO: translate */

        return {
            name: selected.name,
            owner: selected.owner,  // TO DO: check if this is a name or IRI
            battery: selected.latestDeviceStatusEvent?.batteryLevel,
            statusTime: formattedStatusTime,
            placementTime: formattedPlacementTime,
            posX: x,
            posY: y,
            placementMode: selected.latestDevicePlacementEvent?.deviceMode,
            positionName: positionNames[`${x}${y}`],
            deviceType: selected.deviceType,
            devEUI: selected.devEUI,
        }

    }, [selectedPosition, pitchDevices])

    return <div {...props}>
        <PageSubtitle css={css`grid-column: span 2; margin: 0; font-size: 1.5em;`}>
            {titleMessage}{/* TO DO: translate */}
        </PageSubtitle>

        <div>
            <div css={coverCss}>
                <SensorLocationPicker
                    posX={selectedPosition?.posX ?? null}
                    posY={selectedPosition?.posY ?? null}
                    handleSetCoordinates={(x, y) => {
                        handleSelectPosition(x !== null && y !== null ? { posX: x, posY: y } : undefined)
                    }}
                    otherDevices={pitchDevices}
                    pickAnyPosition={true}
                    showGrid={false}
                    showPitchBg={true}
                    pitchBg={pitchBg}
                    showBattery={showBatteryOnGrid}
                    showPlacementLabel={true}
                />
            </div>
            <div css={telemetryStyle.belowGrid}>
                <Orientation
                    heading={pitch?.orientation}
                    css={telemetryStyle.compass}
                />
                <div css={css`opacity: ${isCoverOn ? 1 : 0.5};`}>
                    {isCoverOn ? 'Cover is on' : 'No cover'}{/* to do: translate */}
                </div>
                <SwitcherSimple
                    checked={showBatteryOnGrid}
                    onClick={() => setShowBatteryOnGrid(curr => !curr)}
                    checkedLabel={'Battery'} /* TO DO: translate */
                    unCheckedLabel={'Placement'} /* TO DO: translate */
                    buttonCenter={true}
                    css={telemetryStyle.toggle}
                />
            </div>
        </div>
        {selectedDeviceInfo ? <div>
            <div css={css`
                display: grid; 
                grid-auto-rows: min-content;
                gap: 4px;
                margin-bottom: 1em;
                
                > div {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.5em 1em;
                    background: rgba(0,0,0,0.1);
                    color: ${colors.soft};
                    height: min-content;
                    gap: 1em;
                }
            `}>
                <div> {/* TO DO: translate */}
                    Name  <span> {selectedDeviceInfo.name} </span>
                </div>
                <div>
                    ID <span> {selectedDeviceInfo.devEUI} </span>
                </div>
                <Owner ownerIri={selectedDeviceInfo.owner} />

                <div>
                    Battery  <span> {selectedDeviceInfo.battery ?? 'unknown '}%</span>
                </div>
                <div>
                    Mode  <span> {selectedDeviceInfo.placementMode} </span>
                </div>
                <div >
                    Position <span css={css`text-transform: lowercase;`}> {selectedDeviceInfo.positionName} </span>
                </div>
                <div>
                    Sensor Type <span> {selectedDeviceInfo.deviceType} </span>
                </div>
                <div>
                    Status Updated  <span>{selectedDeviceInfo.statusTime} </span>
                </div>
                <div>
                    Placement Updated <span>{selectedDeviceInfo.placementTime}</span>
                </div>

            </div>

        </div>
            :
            <PageSubtitle css={css`font-size: 1.5em;`}>
                Select a sensor{/* TO DO: translate */}
            </PageSubtitle>
        }

    </div>
}

function Owner({ ownerIri, ...props }) {
    const { data: owner, isBusy: ownerBusy } = useRawApiState(ownerIri)

    return <div>
        Owner  <span>
            {ownerBusy ? <Loader size='1em' /> :
                (owner?.email ?? owner?.name) ?? 'no owner' /* TO DO: translate */
            }
        </span>
    </div>
}

const telemetryStyle = {
    belowGrid: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(0,0,0,0.1); 
        padding: 0.3em 1em;  
            margin-top: 0.5em;
            gap: 1em;
    `,
    compass: css`
        background: transparent; 
        width: min-content;
        height: min-content; 
    `,
    toggle: css`
        border-radius: 5px;
        width: max-content;
        white-space: nowrap;
        place-self: center;
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;
        height: min-content;
    `,
}