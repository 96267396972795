import { rgba } from "polished"

export const colors = {
    white: '#ffffff',
    black: '#000000',
    main1: '#00FFC5',
    alt1: '#18C49C',
    main2: '#02F2FF',
    alt2: '#02F2FF',
    super: '#02a6ff',
    soft: '#B4B4B4',
    light: '#9299AA',
    subtle: '#8499AD',
    liquid: '#717684',
    sublimate: '#6E6E6E',
    solid: '#37414A',
    substance: '#22272A',
    stuff: '#2C343A',
    dark: '#1B1C1F',
    warn: '#F67272',
    pitch: '#00FFC5',
    player: '#02F2FF',
    eventDark: '#2D3339',
    eventLight: "#3A424A",
    dataPrimary: '#A896D6',
    dataSecondary: '#F1AA40',
}

// duplicated to match capitalized names as well as kebabcase ones
export const backendColors = {
    Verygood: '#00FFC5',
    verygood: '#00FFC5',
    Good: '#00FF63',
    Neutral: '#EDFF00',
    Bad: '#F67272',
    "very-good": '#00FFC5',
    "good": '#00FF63',
    "neutral": '#EDFF00',
    "bad": '#F67272',
}

export const testCategoryGroupColors = {
    agronomy: colors.main1,
    playability: colors.main2,
    pitch: colors.main1,
    player: colors.main2,
}

export const fonts = {
    special: 'Oswald, sans-serif',
    title: 'SpaceGrotesk, sans-serif',
    main: 'Heebo, sans-serif',
}

export const timings = {
    snappy: '50ms',
    responsive: '100ms',
    fast: '200ms',
    smooth: '300ms',
}

export const backgrounds = { //to be used as background or background-image
    weatherGradient: `linear-gradient(-6deg, ${rgba(colors.main1, 0.11)} 0%, ${rgba(colors.substance, 0.16)} 50%)`,
    cardGradient: `linear-gradient(170deg, rgba(255,255,255,0) 50%, #02F2FF1C);`
}

export const animations = {
    shake: `
        animation-name: shake;
        animation-duration: ${timings.responsive};
        @keyframes shake {
            25% {transform: translateX(.2em)}
            75% {transform: translateX(-0.2em)}
        }
    `,
    sweep: `
        animation: sweep ${timings.smooth} ease-in-out;
        @keyframes sweep {
            0%    {opacity: 0; transform: translateY(-10px)}
            100%  {opacity: 1; transform: translateY(0px)}
        }
    `
}

export const dataColors = {
    cold: 'rgb(110, 131, 255)',
    warm: 'rgb(220, 50, 50)',
    neutral: 'rgb(200,200,200)',
}
