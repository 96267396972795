import { css } from "@emotion/react"
import { colors, fonts, timings } from "../../../style/vars"
import stadiumIcon from '@assets/icons/stadium2.svg'
import practiceIcon from '@assets/icons/pitch.svg'
import practiceBgBig from '@assets/images/pitch_practice_bg_big.svg'
import { useIntl } from "react-intl"
import { ReactSVG } from "react-svg"
import { useCallback, useContext, useState } from "react"
import Modal from "../../_layout/Modal"
import PitchCardWithData from "../../pitch/PitchCardWithData"
import LazyLoader from "../../_general/LazyLoader"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import Cta from "../../_control/Cta"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import LiveTelemetrySection from "./LiveTelemetrySection"
import PitchSensorsSection from "./PitchSensorsSection"
import Loader from "../../_general/Loader"

export default function PitchTelemetryCard({ ...props }) {
    
    const {
        pitch,
        viewMode,
        setViewMode,
        handleSelectPosition,
        titleMessage,
        loading,
        coverCss,
    } = useContext(PitchTelemetry)
    const { isBusy: typesBusy } = useContext(CovermasterContext)
    const { formatMessage } = useIntl()
    const [pitchModal, setPitchModal] = useState(false)

    const handleSetTab = useCallback((viewMode) => {
        handleSelectPosition()
        setViewMode(viewMode)
    }, [setViewMode, handleSelectPosition])

    return <div css={style.card}>

        <div
            css={style.header}
            onClick={() => setPitchModal(curr => !curr)}
        >
            <h3 css={style.title}>
                {pitch?.name || formatMessage({ id: 'loadingEllipses' })}
            </h3>
            <ReactSVG src={pitch?.stadium ? stadiumIcon : practiceIcon} css={style.headerPitchIcon} />
        </div>

        <div css={style.body}>
            {!typesBusy &&
                <LazyLoader>
                    {loading ? <Loader size='2em' /> :
                        <>
                            {viewMode === 'live' &&
                                <LiveTelemetrySection
                                    css={style.telemetrySection}
                                />
                            }
                            {viewMode === 'historical' &&
                                ''
                            }
                            {viewMode === 'sensors' &&
                                <PitchSensorsSection
                                    pitch={pitch}
                                    titleMessage={titleMessage}
                                    coverCss={coverCss}
                                    css={style.telemetrySection}
                                />
                            }
                        </>}
                </LazyLoader>
            }
        </div>

        <div css={style.buttons}>
            <Cta onClick={() => handleSetTab('live')}
                css={style.button(viewMode === 'live')}> {/* TO DO: translate these */}
                Live
            </Cta>
            <Cta onClick={() => handleSetTab('historical')}
                css={style.button(viewMode === 'historical')}>
                History
            </Cta>
            <Cta onClick={() => handleSetTab('sensors')}
                css={style.button(viewMode === 'sensors')}>
                Sensors
            </Cta>
        </div>

        {pitchModal && pitch &&
            <Modal
                css={css`padding-top: 1em;`}
                onClickClose={() => setPitchModal(false)}
            >
                <PitchCardWithData
                    pitch={pitch}
                    venue={pitch?.venue}
                />
            </Modal>
        }
    </div>
}


const style = {
    card: css`
        max-width: 1100px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: ${colors.solid};
        display: grid;
        grid-template-rows: 5em 1fr 3em;
        grid-template-areas: 
            "header"
            "body"
            "tabs";
        box-shadow: 0 0 4px black;
    `,
    header: css`
        grid-area: header;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 5em;
        -webkit-user-select: none;
        background: no-repeat center url(${practiceBgBig});
        background-size: cover;
        cursor: pointer;
        user-select: none;
        background-color: black;

        &:hover {
            outline: 2px solid ${colors.light};
        }
    `,
    body: css`
        grid-area: body;
        min-height: 25em;
    `,
    buttons: css`
        grid-area: tabs;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    `,
    button: (isActive) => css`
        opacity: ${!isActive ? 0.2 : 1};
        display: grid;
        align-items: center;
        border: none;
        border-radius: unset;

        border-bottom: 1px solid ${isActive ? colors.main1 : 'transparent'};
        border-top: 1px solid transparent !important;
        background: ${isActive ? 'rgba(0,0,0,0.1)' : 'transparent'} !important;
        text-transform: unset;
        font-family: ${fonts.main};
        font-weight: unset;
        font-size: 1.1em;
        transition: background ${timings.smooth}, color ${timings.responsive} !important;

        &:hover {
            &:not(:disabled) {
            border-bottom: 1px solid ${isActive ? colors.main1 : 'transparent'} !important;
            background: rgba(0,0,0,0.1) !important;
            }
        }
    `,
    title: css`
        align-self: center;
        margin-left: 1em;
        font-size: 1.4em;
        font-weight: 500;
        font-family: ${fonts.main};
        overflow-wrap: break-word;
        overflow: hidden;
    `,
    headerPitchIcon: css`
        align-self: center;
        margin-left: 0.7em;
        height: 3em;
        width: 3em;
        color: ${colors.main1};
    `,
    hr: css`
        width: 100%; 
        height: 1px; 
        opacity: 0.2; 
        color: ${colors.light};
    `,
    telemetrySection: css`
        display: grid;
        height: 100%;
        width: 100%;
        grid-template: auto 1fr / 1fr 1fr;
        padding: 1em;
        gap: 1em;
    `
}