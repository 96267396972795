import { useCallback, useEffect, useMemo, useState } from "react"
import FancyDropdown from "../../_control/FancyDropdown"
import { FormattedMessage, useIntl } from "react-intl"
import { css } from "@emotion/react"
import TextField from "../../_control/TextField"
import { colors } from "../../../style/vars"
import Loader from "../../_general/Loader"
import SensorLocationPicker from "./SensorLocationPicker"
import SwitcherSimple from "../../_control/SwitcherSimple"
import Cta from "../../_control/Cta"
import useLdjsonApi from "../../../hooks/useLdjsonApi"
import useRawApiState from "../../../hooks/useRawApiState"
import { isEqual } from "lodash"

export default function SensorForm({ selectedDevice, afterRequest, pitchGroupedDevices, ...props }) {
    const { formatMessage } = useIntl()
    const { post, put } = useLdjsonApi()
    const creatingNew = !selectedDevice

    const [formState, setFormState] = useState(creatingNew ?
        {
            name: '',
            deviceType: 'cover-master',
            devEUI: '',
            active: true,
            deleted: false,
            pitch: null,
            posX: null,
            posY: null,
            isUnderCover: false,
            userGroup: null,
        }
        :
        {
            name: selectedDevice.name,
            deviceType: selectedDevice.deviceType,
            devEUI: selectedDevice.devEUI,
            active: selectedDevice.active,
            deleted: selectedDevice.deleted,
            pitch: selectedDevice.latestDevicePlacementEvent?.pitch?.id,
            posX: selectedDevice.latestDevicePlacementEvent?.posX,
            posY: selectedDevice.latestDevicePlacementEvent?.posY,
            isUnderCover: selectedDevice.latestDevicePlacementEvent?.deviceMode ?
                (selectedDevice.latestDevicePlacementEvent?.deviceMode === 'under-cover')
                : 'unassigned'
            ,
            userGroup: null, // set via useEffect after fetching owner events
        }
    )

    ///// OWNERSHIP
    const { data: userGroups, isBusy: userGroupsBusy } = useRawApiState('/api/current/user-groups')
    // get the usergroup that currently owns this sensor, if editing
    const { data: deviceData, isBusy: deviceBusy } = useRawApiState(
        selectedDevice ? `/api/current/devices/${selectedDevice.id}` : undefined,
        {},
        [selectedDevice]
    )

    const latestOwnershipUsergroup = useMemo(() => {
        if (!deviceData?.deviceOwnerships.length) return null
        return deviceData.deviceOwnerships?.reduce((a, b) => {
            const aTimestamp = a.timestamp || a
            const bTimestamp = b.timestamp
            return aTimestamp < bTimestamp ? b : a
        }).userGroup?.['@id']
    }, [deviceData])

    const handleSetOwner = useCallback((value, clearPosition = true) => {
        setFormState(prev => clearPosition ? {
            ...prev,
            pitch: null, // clear pitch and position if owner changes
            posX: null,
            posY: null,
            userGroup: value,
        } :
            {
                ...prev,
                userGroup: value,
            }
        )
    }, [])

    useEffect(() => {
        handleSetOwner(latestOwnershipUsergroup, false)
    }, [latestOwnershipUsergroup])

    const ownershipOptions = useMemo(() => {
        const options = userGroups?.map((userGroup) => {
            return ({ value: userGroup['@id'], label: userGroup.name })
        }).toSorted((a, b) => a.label.localeCompare(b.label), {}) ?? []
        //options.unshift({ value: null, label: 'none' })/* to do: translate this */
        return options
    }, [userGroups])

    const userGroupNumber = useMemo(() => {
        return userGroups?.find(x => x['@id'] === formState.userGroup)?.id  // makes sure the rawApiState won't spam requests
    }, [formState, userGroups])

    ///// PITCH
    const pitchNumber = useMemo(() => {
        return formState.pitch // makes sure the rawApiState won't spam requests
    }, [formState])

    const { data: pitchData, isBusy: pitchBusy } = useRawApiState(
        pitchNumber ? `/api/current/pitches/${pitchNumber}` : undefined,
        {},
        [pitchNumber]
    )

    const { data: pitches, isBusy: pitchesBusy } = useRawApiState(
        userGroupNumber ? `/api/current/user-groups/${userGroupNumber}/pitches` : undefined,
        {},
        [userGroupNumber]
    )

    const pitchOptions = useMemo(() => {
        const options = pitches?.map(({ id, name }) => ({
            value: id,
            label: name,
        })) ?? []
        options.unshift({
            value: null,
            label: formatMessage({ id: 'none' })
        })
        return options
    }, [pitches])

    const pitchOtherDevices = useMemo(() => {
        const devices = pitchGroupedDevices[pitchNumber]
        return devices?.filter(device => device.id !== selectedDevice?.id) ?? []
    }, [pitchGroupedDevices, pitchNumber, selectedDevice])

    // LOADING AND VALIDATION STATE
    const [submitBusy, setSubmitBusy] = useState(false)

    const loadingForm = useMemo(() => {
        return userGroupsBusy || deviceBusy || !userGroups
    }, [userGroupsBusy, deviceBusy, pitchData, userGroups])

    const isSubmittable = useMemo(() => {
        return formState.name &&
            formState.devEUI &&
            formState.userGroup &&
            !pitchBusy &&
            !submitBusy &&
            !userGroupsBusy &&
            !deviceBusy &&
            !pitchesBusy
    }, [formState, pitchBusy, submitBusy, userGroupsBusy, deviceBusy, pitchesBusy])

    // STATE SETTERS
    const handleSetPitch = useCallback((value) => {
        setFormState(prev => ({
            ...prev,
            pitch: value,
            posX: null, // clear the position if pitch changes
            posY: null,
        }))
    }, [])

    const handleSetCoordinates = useCallback((x, y) => {
        setFormState(prev => ({
            ...prev,
            posX: x,
            posY: y,
        }))
    }, [])

    const handleToggleMode = useCallback(() => {
        setFormState(prev => ({
            ...prev,
            isUnderCover: !prev.isUnderCover,
        }))
    }, [])

    const submit = useCallback(async () => {
        if (!isSubmittable) return

        setSubmitBusy(true)
        try {
            // create sensor
            const sensorPayload = { ...formState }
            delete sensorPayload.pitch
            delete sensorPayload.posX
            delete sensorPayload.posY
            delete sensorPayload.isUnderCover
            delete sensorPayload.userGroup

            let responseData
            if (creatingNew) {
                const sensorResponse = await post('/api/current/devices', { body: sensorPayload })
                responseData = sensorResponse.data
            } else {
                delete sensorPayload.devEUI
                const sensorResponse = await put(`/api/current/devices/${selectedDevice.id}`, { body: sensorPayload })
                responseData = sensorResponse.data
            }

            // if necessary (ownership has changed), change ownership
            if (responseData && latestOwnershipUsergroup !== formState.userGroup) {
                try {
                    const ownershipPayload = {
                        device: responseData['@id'],
                        userGroup: formState.userGroup,
                        active: true,
                        deleted: false,
                    }
                    await post('/api/current/device-ownerships', { body: ownershipPayload })

                } catch (e) {
                    console.error()
                }
            }

            // if necessary, (new sensor, or placement has changed) create placement event
            if (responseData) {
                try {
                    const hasPitch = formState.pitch && pitchData
                    const hasLocation = formState.posY && formState.posX && hasPitch
                    const placementPayload = {
                        device: responseData['@id'],
                        pitch: hasPitch ? pitchData['@id'] : null,
                        posX: hasLocation ? formState.posX : null,
                        posY: hasLocation ? formState.posY : null,
                        timestamp: (Math.floor((new Date()).getTime() / 1000)),
                        active: true,
                        deleted: false,
                        deviceMode: hasLocation ? (formState.isUnderCover ? 'under-cover' : 'over-cover') : 'unassigned',
                    }

                    if (creatingNew || hasPlacementChanged(placementPayload, responseData)) {
                        await post('/api/current/device-placement-events', { body: placementPayload })
                    }
                } catch (e) {
                    console.error(e)
                }
            }

        } catch (e) {
            console.error(e)
        } finally {
            setSubmitBusy(false)
            afterRequest()
        }
    }, [formState, isSubmittable, post, afterRequest, pitchData, selectedDevice, latestOwnershipUsergroup])

    return loadingForm ? <div css={css`height: 50vh;`}>
        <Loader size="2em" />
    </div> :
        <div css={css`display: grid; gap: 1.5em; font-size: 0.85em;`}>

            <div css={style.section}>
                <span>
                    Ownership {/* TO DO translate */}
                </span>
                <FancyDropdown
                    options={ownershipOptions}
                    css={style.dropdown}
                    value={ownershipOptions?.find(({ value }) => (value === formState.userGroup)) ?? { label: 'none', value: null }} /* TO DO translate this */
                    onChange={({ value }) => handleSetOwner(value)}
                />
            </div>

            <div css={style.section}>
                <span>
                    Pitch {/* TO DO translate */}
                </span>
                <FancyDropdown
                    options={pitchOptions}
                    placeholder={formatMessage({ id: 'none' })}
                    css={style.dropdown}
                    value={pitchesBusy ? undefined : pitchOptions?.find(({ value }) => (value === formState.pitch))}
                    onChange={({ value }) => handleSetPitch(value)}
                    disabled={pitchesBusy}
                />
            </div>

            <div css={style.section}>
                <span>
                    Choose a name or number {/* TO DO translate */}
                </span>
                <TextField
                    autoFocus={false}
                    css={style.textField(false)}
                    value={formState.name}
                    maxLength={30}
                    disabled={false}
                    onChange={(e) => setFormState(prev => ({ ...prev, name: e.target.value }))}
                />
            </div>

            <div css={style.section}>
                <span>
                    Sensor Id {/* TO DO translate */}
                </span>
                <TextField
                    autoFocus={false}
                    css={style.textField(!creatingNew)}
                    value={formState.devEUI}
                    maxLength={30}
                    disabled={!creatingNew}
                    onChange={(e) => setFormState(prev => ({ ...prev, devEUI: e.target.value }))}
                />
            </div>

            <div css={[style.section, css`opacity: ${formState.pitch ? 1 : 0.4};`]}>

                <span>
                    Choose a position {/* TO DO translate */}
                </span>
                <div css={css` width: min(100%, 18em);`}>
                    <SensorLocationPicker
                        posX={formState.posX}
                        posY={formState.posY}
                        handleSetCoordinates={handleSetCoordinates}
                        disabled={!formState.pitch}
                        isUnderCover={formState.isUnderCover}
                        otherDevices={pitchOtherDevices}
                    />
                    <SwitcherSimple
                        checked={formState.isUnderCover}
                        onClick={handleToggleMode}
                        unCheckedLabel='over cover' /* TO DO translate this */
                        checkedLabel='under cover'  /* TO DO translate this */
                        buttonCenter={true}
                        css={css`
                            margin-top: 1em;
                            visibility: ${!!formState.pitch ? 'visible' : 'hidden'};
                        `}
                    />
                </div>
            </div>

            <div css={css`
                display: flex;
                flex-direction: row-reverse;
            `}>
                <Cta
                    onClick={() => submit()}
                    disabled={loadingForm || !isSubmittable}
                >
                    <FormattedMessage id='save' />
                </Cta>
            </div>

        </div>
}

const style = {
    textField: (disabled) => css`
        background: ${disabled ? colors.eventDark : colors.eventLight};
        color: white;
        font-size: 1em;
        opacity: ${disabled ? 0.8 : 1}
    `,
    dropdown: css`
        width: 17.5em;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;

        >div {
            padding: 1em;
        }
    `,
    section: css`
        display: grid;
        gap: 0.4em;
    `
}

function hasPlacementChanged(placementPayload, deviceAfterPostPut) {

    //backend always creates one placement event after creating a device, but in theory can be deleted
    const placementsList = deviceAfterPostPut.devicePlacementEvents // can be an empty array

    if (!placementsList.length) return false

    const latestOfList = placementsList.reduce((a, b) => {
        const aTimestamp = a.timestamp || a
        const bTimestamp = b.timestamp
        return aTimestamp < bTimestamp ? b : a
    })

    try {
        const payloadCopy = { ...placementPayload }
        delete payloadCopy.timestamp

        const payloadShapedData = {
            device: latestOfList.device,
            pitch: latestOfList?.pitch?.['@id'] ?? null,
            posX: latestOfList.posX,
            posY: latestOfList.posY,
            active: latestOfList.active,
            deleted: latestOfList.deleted,
            deviceMode: latestOfList.deviceMode,
        }

        return !isEqual(payloadCopy, payloadShapedData)
    } catch (e) {
        console.error(e)
    }

}